import React, { useEffect, useState } from 'react';
import _ from 'lodash';

const FormikCalculationInput = ({
  formik,
  label = '',
  required = false,
  name,
  fullWidth,
  onChange = null,
  ...props
}) => {
  const onChangeFunction = onChange ? onChange : formik.handleChange;
  const [value, setValue] = useState(props.value ? props.value : _.at(formik.values, name)[0]);

  useEffect(() => {
    if (!_.isNil(props.value) || !_.isNil(_.at(formik.values, name)[0])) {
      //when props value is 0 this is not getting triggered
      formik.setFieldValue(
        name,
        !_.isNil(props.value) ? props.value : _.at(formik.values, name)[0]
      );
      setValue(!_.isNil(props.value) ? props.value : _.at(formik.values, name)[0]);
    }
  }, [_.at(formik.values, name)[0], props.value]);
  return (
    <div className={fullWidth ? 'w-full' : ''}>
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label} {required && <span className="text-rose-500">*</span>}
      </label>
      <input
        type="text"
        value={value}
        onChange={onChangeFunction}
        name={name}
        onBlur={formik.handleBlur}
        className="w-full form-input"
        {...props}
      />
      {formik.errors[name] && formik.touched[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikCalculationInput;
