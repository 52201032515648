import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { useFormik } from "formik";
import { object, string } from "yup";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { editStatus, fetchStatuses } from "../../app/reducers/Status/statusSlice";

const EditStatusModal = ({
  setOpenEditModal,
  openEditModal,
  requestData,
}) => {
  const computeInitialValue = (data) => {
    if (data) return { ...data };
    else {
      return {
        name: "",
        order:"",
      };
    }
  };
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: computeInitialValue(requestData),
    validationSchema: object({
      name: string().required(),
    }),
    onSubmit: async(values, { resetForm }) => {
      console.log("values", values);
      const editObj = {
        _id : values?._id,
        ...values
      }
      await dispatch(editStatus(editObj));
      await dispatch(fetchStatuses({ populate: true, sort:{order:1} }));
      resetForm();
      setOpenEditModal(false);
    },
  });
  return (
    <ModalBasic modalOpen={openEditModal} setModalOpen={setOpenEditModal}>
      <div className="p-4">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-4 mb-4"
        >
          <FormikInputGroup formik={formik} label="Name" name="name" required />
          <FormikInputGroup formik={formik} label="Order" name="order" type='number' />
          <div>
            <PrimaryButton type="submit">Edit</PrimaryButton>
          </div>
        </form>
      </div>
    </ModalBasic>
  );
};

export default EditStatusModal;
