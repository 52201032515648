import React, { useEffect } from 'react';
import ModalBasic from '../../../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import FormikInputGroup from '../../../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../../../components/formik/FormikSelectGroup';
import { PAYMENT_METHOD } from '../../../../utils/dropdownOptions';
import PrimaryButton from '../../../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCreditbook,
  fetchCreditbooks,
} from '../../../../app/reducers/Creditbook/creditbookSlice';
import { createCashbook } from '../../../../app/reducers/Cashbook/cashbookSlice';
import { generateOptions } from '../../../../utils/Utils';
import { fetchLocations, getLocation } from '../../../../app/reducers/Location/locationSlice';
import { number, object, string } from 'yup';
import { fetchBanks, getBanks } from '../../../../app/reducers/Bank/bankSlice';
import { createBankbook } from '../../../../app/reducers/Bankbook/bankbookSlice';

const PayAmountModal = ({ isOpen, setIsOpen, data }) => {
  const saleLocationInfoId = localStorage.getItem('saleLocationInfoId');
  const { location, loading: locationLoading } = useSelector(getLocation);
  const { bank, loading: bankLoading } = useSelector(getBanks);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBanks());
    dispatch(fetchLocations());
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user: data?._id,
      method: '',
      amount: '',
      location: saleLocationInfoId ? JSON.parse(saleLocationInfoId) : '',
    },
    validationSchema: object({
      location: string().when('method', {
        is: 'cash',
        then: string().required('Location is required for cash transaction'),
        otherwise: string(),
      }),
      method: string().required(),
      user: string().required(),
      amount: number().min(1).required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await dispatch(
          createCreditbook({
            transaction_type: 'credit',
            amount: values.amount,
            user: values.user,
          })
        );
        if (values.method == 'cash') {
          await dispatch(
            createCashbook({
              transaction_type: 'debit',
              amount: values.amount,
              location: values.location,
              expense_category: 'PAY_AMOUNT',
            })
          );
        } else {
          dispatch(
            createBankbook({
              bank: values.method,
              transaction_type: 'debit',
              amount: values.amount,
              expense_category: 'PAY_AMOUNT',
            })
          );
        }
        await dispatch(
          fetchCreditbooks({
            populate: true,
            'user._id': data?._id,
          })
        );
        setIsOpen(false);
        resetForm();
      } catch (error) {
        console.log('Error', error);
      }
    },
  });
  return (
    <ModalBasic modalOpen={isOpen} setModalOpen={setIsOpen} title={'Pay Amount'}>
      <form onSubmit={formik.handleSubmit} className="p-4 flex flex-col gap-3">
        <FormikInputGroup label="Customer" name={'user'} formik={formik} readOnly />
        <FormikSelectGroup
          name="location"
          label="Location / Store"
          formik={formik}
          options={generateOptions({
            array: location?.docs ?? [],
            valueField: '_id',
            labelField: 'name',
          })}
        />{' '}
        <FormikSelectGroup
          label="Method"
          name={'method'}
          formik={formik}
          options={[
            ...(bank.docs ?? []).map(item => ({
              value: item._id,
              label: item.name,
            })),
            ...PAYMENT_METHOD.filter(item => item.value !== 'credit'),
          ]}
        />
        <FormikInputGroup type={'number'} name={'amount'} label="Amount" formik={formik} />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </ModalBasic>
  );
};

export default PayAmountModal;
