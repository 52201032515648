import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";

const ViewPendingInventoryModal = ({ isOpen, setIsOpen, data }) => {
  //   const { string } = useParams();
  //   const stringObj = QueryString.parse(string);
  //   console.log(stringObj);
  const [pendingData, setPendingData] = useState({});

  const fetchPendingInventory = async () => {
    const string = QueryString.stringify({ ...data });
    const pendingInventoryResp = await authAxiosInstance.get(
      `/productInventory/pending?${string}`
    );
    console.log(pendingInventoryResp);
    setPendingData(pendingInventoryResp.data.data);
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      fetchPendingInventory();
    }
  }, [QueryString.stringify(data)]);

  return (
    <ModalBasic
      title="View Pending"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
    >
      <div className="border-2 m-3 p-3 flex flex-col gap-2">
        <h1 className="font-semibold text-xl">Type: Pending Purchase Order</h1>
        {!_.isEmpty(pendingData.pendingPurchaseOrder) ? (
          <>
            <p>
              Product: {pendingData?.pendingPurchaseOrder[0]?.product.name} -{" "}
              {pendingData?.pendingPurchaseOrder[0]?.product.masterSku}
            </p>
            <p>
              Pending:{" "}
              <span>{pendingData?.pendingPurchaseOrder[0]?.pending}</span>
            </p>
          </>
        ) : (
          <span>No Pending Data</span>
        )}
      </div>

      <div className="border-2 m-3 p-3 flex flex-col gap-2">
        <h1 className="font-semibold text-xl">Type: Pending Stock Transfer</h1>
        {!_.isEmpty(pendingData?.pendingStockTransfer) ? (
          <>
            <p>
              Product: {pendingData?.pendingStockTransfer[0]?.product?.name} -{" "}
              {pendingData?.pendingStockTransfer[0]?.product.masterSku}
            </p>
            <p>
              Pending:{" "}
              <span>{pendingData?.pendingStockTransfer[0]?.pending}</span>
            </p>
          </>
        ) : (
          <span>No Pending Data</span>
        )}
      </div>
    </ModalBasic>
  );
};

export default ViewPendingInventoryModal;
