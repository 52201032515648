import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { array, object, string } from "yup";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { generateOptions } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  getProducts,
} from "../../app/reducers/Product/productSlice";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { X } from "react-feather";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import { createLead, fetchLeads } from "../../app/reducers/Lead/leadSlice";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import {
  fetchReferences,
  getReferences,
} from "../../app/reducers/Reference/referenceSlice";
import {
  fetchStatuses,
  getStatuses,
} from "../../app/reducers/Status/statusSlice";
import { ClipLoader } from "react-spinners";
import FormikCreatableSelect from "../../components/formik/FormikCreatableSelect";
import { authAxiosInstance } from "../../utils/axiosConfig";
import FormikTime from "../../components/formik/FormikTime";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { LEAD_MEETING_SCHEDULE } from "../../utils/dropdownOptions";
import FormikLabel from "../../components/formik/FormikLabel";
import { getAuth } from "../../app/reducers/Auth/authSlice";
import {
  fetchEmployees,
  getEmployees,
} from "../../app/reducers/Users/EmployeeSlice";
import { fetchGroups, getGroups } from "../../app/reducers/Group/groupSlice";

// const generateOptions = (data)=>{
//   return data?.map((ele)=>({
//     label : ele.name,
//     value : ele._id
//   }))
// };

const AddLead = () => {
  const [isLoading, setIsLoading] = useState({
    ref: false,
    stat: false,
    grp: false,
  });
  const { user } = useSelector(getAuth);
  const { product } = useSelector(getProducts);
  const { reference, loading: refLoading } = useSelector(getReferences);
  const { status, loading: statsLoading } = useSelector(getStatuses);
  const { group, loading: groupLoading } = useSelector(getGroups);
  const { employees, loading: employeesLoading } = useSelector(getEmployees);
  const dispatch = useDispatch();

  const convertCustomTimeToDate = (selectedDate, customTime) => {
    const milliseconds = parseInt(selectedDate, 10);
    const date = new Date(milliseconds);
    const formattedDateString = date.toString();

    const timeString = customTime.split(" ")[4];

    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    const newDate = new Date(formattedDateString);

    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds(seconds);

    return newDate;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      // email: "",
      emails: [""],
      // contactNumber: "91",
      contactNumbers: ["91"],
      reference: "",
      notes: "",
      products: [],
      isMeetingSchedule: "no",
      meetingDate: null,
      meetingTime: null,
      meetingDateAddToCalendar: null,
      files: [],
      status: "",
      place: "",
      group: "",
      createdBy: user._id,
      isCallSchedule: "no",
      callScheduleDate: null,
      callScheduleTime: null,
      callScheduleDateAddToCalendar: null,
    },
    validationSchema: object({
      name: string().required(),
      reference: string().required(),
      status: string().required(),
      group: string().required(),
      products: array().required(),
      contactNumbers: array().min(1, "At least one contact number is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const existingPhoneResp = await dispatch(
        fetchLeads({
          editStatus: "approved",
          contactNumbers: { $in: values.contactNumbers },
        })
      );

      let text = "";

      if (existingPhoneResp.payload.data.docs.length > 0) {
        const data = existingPhoneResp.payload.data.docs[0];
        text = `${
          data.leadNumber
        } Lead is already present with this ${data?.contactNumbers
          ?.map((el) => el)
          .join(", ")}${
          data?.contactNumbers?.length > 0 ? "," : ""
        } phone number, if you want to continue press\n OK or Cancel`;
      }

      if (text && window.confirm(text) === false) {
        return;
      }
      if (values?.meetingDate !== null || values?.callScheduleDate !== null) {
        const newObj = {
          ...values,
          meetingDate: values?.meetingDate
            ? convertCustomTimeToDate(
                values.meetingDate,
                values.meetingTime?.toString()
              )
            : null,
          meetingTime: values?.meetingDate
            ? convertCustomTimeToDate(
                values.meetingDate,
                values.meetingTime?.toString()
              )
            : null,
          callScheduleDate: values?.callScheduleDate
            ? convertCustomTimeToDate(
                values.callScheduleDate,
                values.callScheduleTime?.toString()
              )
            : null,
          callScheduleTime: values?.callScheduleDate
            ? convertCustomTimeToDate(
                values.callScheduleDate,
                values.callScheduleTime?.toString()
              )
            : null,
        };

        console.log(newObj, "newObj");
        dispatch(createLead(newObj));
      } else {
        dispatch(createLead(values));
      }
      resetForm();
    },
  });

  useEffect(() => {
    dispatch(fetchProducts({ populate: true }));
    dispatch(fetchReferences({ populate: true }));
    dispatch(fetchStatuses({ populate: true }));
    dispatch(fetchEmployees({ populate: true }));
    dispatch(fetchGroups({ populate: true }));
  }, []);

  // useEffect(() => {
  //   const setDateAndTimeFields = (isScheduled, dateField, timeField) => {
  //     if (isScheduled === "yes") {
  //       formik.setFieldValue(dateField, moment().startOf("day").valueOf());
  //       formik.setFieldValue(timeField, moment().toDate());
  //     } else {
  //       formik.setFieldValue(dateField, null);
  //       formik.setFieldValue(timeField, null);
  //     }
  //   };

  //   setDateAndTimeFields(
  //     formik.values.isMeetingSchedule,
  //     "meetingDate",
  //     "meetingTime"
  //   );
  //   setDateAndTimeFields(
  //     formik.values.isCallSchedule,
  //     "callScheduleDate",
  //     "callScheduleTime"
  //   );
  // }, [formik.values.isMeetingSchedule, formik.values.isCallSchedule]);

  const handleRefCreate = async (inputValue, route, formikName) => {
    setIsLoading((state) => ({ ...state, ref: true }));
    let data = { name: inputValue };
    const resp = await authAxiosInstance.post(`/${route}`, data);

    if (resp.data) {
      await dispatch(fetchReferences({ populate: true })).then((res) => {
        formik.setFieldValue(formikName, resp.data?.data?._id.toString());
      });
      setIsLoading((state) => ({ ...state, ref: false }));
    }
  };

  const handleStatCreate = async (inputValue, route, formikName) => {
    setIsLoading((state) => ({ ...state, stat: true }));
    let data = { name: inputValue };
    const resp = await authAxiosInstance.post(`/${route}`, data);

    if (resp.data) {
      await dispatch(fetchStatuses({ populate: true })).then((res) => {
        formik.setFieldValue(formikName, resp.data?.data?._id.toString());
      });
      setIsLoading((state) => ({ ...state, stat: false }));
    }
  };
  const handelGroupCreate = async (inputValue, route, formikName) => {
    setIsLoading((state) => ({ ...state, grp: true }));
    let data = { name: inputValue };
    const resp = await authAxiosInstance.post(`/${route}`, data);

    if (resp.data) {
      await dispatch(fetchGroups({ populate: true })).then((res) => {
        formik.setFieldValue(formikName, resp.data?.data?._id.toString());
      });
      setIsLoading((state) => ({ ...state, grp: false }));
    }
  };

  return (
    <PageWithCard heading="Add Lead">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikInputGroup
              formik={formik}
              label="Name"
              name="name"
              required
            />
          </div>
          <div className="w-full">
            <FormikInputGroup formik={formik} label="Place" name="place" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikProvider value={formik} name="contactNumbers">
              <FieldArray
                name="contactNumbers"
                render={(arrayHelpers) => {
                  return (
                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                      {formik?.values?.contactNumbers?.map((ele, index) => (
                        <div className="mb-2" key={index}>
                          <div className="relative">
                            {index !== 0 && (
                              <X
                                className=" right-0 text-red-500"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            )}

                            <div className="flex flex-col gap-2">
                              <div className="w-full flex flex-row justify-between items-center">
                                <FormikLabel label="Phone" />
                                <div className="w-[80%]">
                                  <FormikPhoneInputGroup
                                    formik={formik}
                                    name={`contactNumbers.${index}`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            arrayHelpers.push("91");
                          }}
                        >
                          Add more
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          </div>
          <div className="w-full">
            {/* <FormikInputGroup formik={formik} label="Email" name="email" /> */}
            <FormikProvider value={formik} name="emails">
              <FieldArray
                name="emails"
                render={(arrayHelpers) => {
                  return (
                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                      {formik?.values?.emails?.map((ele, index) => (
                        <div className="mb-2" key={index}>
                          <div className="relative">
                            {index !== 0 && (
                              <X
                                className=" right-0 text-red-500"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            )}

                            <div className="flex flex-col gap-2">
                              <div className="w-full flex flex-row justify-between items-center">
                                <FormikLabel label="Email" />
                                <div className="w-[80%]">
                                  <FormikInputGroup
                                    formik={formik}
                                    name={`emails.${index}`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            arrayHelpers.push("");
                          }}
                        >
                          Add more
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            {refLoading ? (
              <ClipLoader />
            ) : (
              <FormikCreatableSelect
                formik={formik}
                label="Source"
                name="reference"
                required
                isLoading={isLoading.ref}
                isDisabled={isLoading.ref}
                options={generateOptions({
                  array: reference?.docs ? reference?.docs : [],
                  labelField: "name",
                  valueField: "_id",
                })}
                onCreateOption={(value) =>
                  handleRefCreate(value, "reference", "reference")
                }
              />
            )}
          </div>
          <div className="w-full">
            {statsLoading ? (
              <ClipLoader />
            ) : (
              <FormikCreatableSelect
                formik={formik}
                label="Status"
                name="status"
                required
                isLoading={isLoading.stat}
                isDisabled={isLoading.stat}
                options={generateOptions({
                  array: status?.docs ? status?.docs : [],
                  labelField: "name",
                  valueField: "_id",
                })}
                onCreateOption={(value) =>
                  handleStatCreate(value, "status", "status")
                }
              />
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikMultiSelect
              formik={formik}
              label="Products"
              name="products"
              required
              options={generateOptions({
                array: product?.docs ? product?.docs : [],
                labelField: "name",
                valueField: "_id",
              })}
            />
          </div>
          <div className="w-full">
            {groupLoading ? (
              <ClipLoader />
            ) : (
              <FormikCreatableSelect
                formik={formik}
                label="Group"
                name="group"
                required
                isLoading={isLoading.grp}
                isDisabled={isLoading.grp}
                options={generateOptions({
                  array: group?.docs ? group?.docs : [],
                  labelField: "name",
                  valueField: "_id",
                })}
                onCreateOption={(value) =>
                  handelGroupCreate(value, "group", "group")
                }
              />
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikSelectGroup
              formik={formik}
              label="Meeting Schedule Status"
              name="isMeetingSchedule"
              options={LEAD_MEETING_SCHEDULE}
            />
          </div>
          <div className="w-full">
            {formik.values.isMeetingSchedule === "yes" && (
              <div className="w-full flex flex-row items-center gap-4">
                <div className="w-full">
                  <FormikInputDateGroup
                    formik={formik}
                    label="Meeting Date"
                    name="meetingDate"
                  />
                </div>
                <div className="w-full">
                  <FormikTime
                    formik={formik}
                    label="Meeting Time"
                    name="meetingTime"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikSelectGroup
              formik={formik}
              label="Call Schedule Status"
              name="isCallSchedule"
              options={LEAD_MEETING_SCHEDULE}
            />
          </div>
          <div className="w-full">
            {formik.values.isCallSchedule === "yes" && (
              <div className="w-full flex flex-row items-center gap-4">
                <div className="w-full">
                  <FormikInputDateGroup
                    formik={formik}
                    label="Call Schedule Date"
                    name="callScheduleDate"
                  />
                </div>
                <div className="w-full">
                  <FormikTime
                    formik={formik}
                    label="Call Schedule Time"
                    name="callScheduleTime"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <FormikTextareaGroup formik={formik} label="Notes" name="notes" />

        <FormikProvider value={formik}>
          <FieldArray
            name="files"
            render={(arrayHelpers) => {
              return (
                <>
                  <div className="mb-2 ">
                    <SecondaryButton
                      onClick={(e) => {
                        e.stopPropagation();
                        arrayHelpers.push({
                          fileName: "",
                          fileDocs: "",
                        });
                      }}
                    >
                      Add Files
                    </SecondaryButton>
                  </div>
                  <table className="table-auto w-full">
                    <tbody className="text-sm divide-y divide-slate-200">
                      {formik.values.files.map((ele, i) => (
                        <tr key={i} className="flex flex-row items-start pl-6">
                          <td className="px-2 pb-7 whitespace-nowrap first:pl-5 last:pr-5 relative w-[30%] ">
                            <div className="">
                              <X
                                onClick={(e) => {
                                  e.stopPropagation();
                                  arrayHelpers.remove(i);
                                }}
                                color="red"
                                className="absolute top-[40%] left-[-15%] md:left-[-10%]"
                              />
                              <FormikInputGroup
                                formik={formik}
                                name={`files.${i}.fileName`}
                                label="File Name"
                              />
                            </div>
                          </td>
                          <td className="w-[50%] flex flex-wrap ">
                            <div className="text-ellipsis overflow-hidden whitespace-nowrap pl-2">
                              <FormikDirectFileUpload
                                name={`files.${i}.fileDocs`}
                                formik={formik}
                                label="Choose File"
                                randomize={true}
                                location={`Sale/documents/`}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              );
            }}
          />
        </FormikProvider>
        <FormikSelectGroup
          formik={formik}
          label="Created By"
          options={generateOptions({
            array: employees?.docs?.map((ele) => ({
              name: `${ele.firstName} ${ele.lastName}`,
              value: ele._id,
            })),
            labelField: "name",
            valueField: "value",
          })}
          name="createdBy"
        />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </PageWithCard>
  );
};

export default AddLead;
