import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { generateOptions } from '../../utils/Utils';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { Minus, Plus } from 'react-feather';
import { toast } from 'react-toastify';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import SelectedProductRow from '../SalesOrder/SelectedProductRow';
import ProductSelect from '../SalesOrder/ProductSelect';
import {
  fetchCreditbook,
  fetchCreditbooks,
  getCreditbook,
} from '../../app/reducers/Creditbook/creditbookSlice';
import ConsumeSelectProductRow from './ConsumeSelectProductRow';
import { CONSUME_TYPES } from '../../utils/dropdownOptions';

const TableHeader = ({ headers }) => {
  return (
    <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers?.map(header => (
          <th key={header.name} className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div style={header.style ?? {}} className="font-semibold text-left">
              {header.name}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
const Consume = () => {
  const { creditbook, loading: creditbookLoading } = useSelector(getCreditbook);
  console.log(creditbook?.docs?.[0].balance);
  const { location } = useSelector(getLocation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saleLocationInfoID = JSON.parse(localStorage.getItem('saleLocationInfoId'));

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: saleLocationInfoID ?? '',
      type: '',
      customerId: '',
      customerName: '',
      customerPhone: '',
      products: [],
      credit_balance: creditbook?.docs?.[0]?.balance ? creditbook?.docs[0].balance : 0,
    },
    validationSchema: Yup.object({
      customerId: Yup.string().required(),
      customerName: Yup.string().required(),
      customerPhone: Yup.string().required(),
      product: Yup.array().of(
        Yup.object({
          product: Yup.string(),
          productName: Yup.string(),
          sku: Yup.string(),
          qty: Yup.number(),
        })
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      let filterProduct = values?.products?.filter(ele => ele.product_sku);
      if (filterProduct.length > 0) {
        let payload = { ...values, products: filterProduct };
        const generatingNewSale = await authAxiosInstance.post('/consume', payload);
        if (generatingNewSale) {
          await toast.success('Consume Created Successfully');
          resetForm();
        } else {
          toast.error('Error generating Sale');
        }
      } else {
        alert('Please Select Atleast one Barcode');
      }
    },
  });

  return (
    <PageWithCard heading="Create Consume">
      <div className="mb-3 flex flex-row gap-3">
        <SecondaryButton
          onClick={e => {
            e.stopPropagation();
            navigate('/users/add-customer');
          }}
          type="button"
        >
          Add Customer
        </SecondaryButton>
        <SecondaryButton
          onClick={e => {
            e.stopPropagation();
            navigate('/location/assignLocation');
          }}
          type="button"
        >
          Assign Sale Location
        </SecondaryButton>
      </div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikSelectGroup
          options={generateOptions({
            array: CONSUME_TYPES ?? [],
            labelField: 'label',
            valueField: 'value',
          })}
          label="Consume Type"
          name="type"
          formik={formik}
        />

        <FormikAsyncSelect
          name="customerId"
          formik={formik}
          label="Search Customer"
          getOptions={async value => {
            const string = QueryString.stringify({ search: value });
            const customers = await authAxiosInstance.get(`users/customer?${string}`);
            const options = customers?.data?.data?.docs?.map(ele => ({
              label: `${ele?.firstName} ${ele?.lastName} / ${ele?.username}`,
              value: ele?._id,
            }));
            return options;
          }}
          onChange={async selectedOption => {
            const string = QueryString.stringify({
              _id: selectedOption?.value,
            });
            await dispatch(
              fetchCreditbooks({
                populate: true,
                'user._id': selectedOption?.value,
              })
            );
            const resp = await authAxiosInstance.get(`users/customer?${string}`);
            const user = resp?.data?.data?.docs[0];

            formik.setFieldValue('customerId', user._id);
            formik.setFieldValue('customerName', `${user.firstName} ${user.lastName}`);
            formik.setFieldValue('customerPhone', user.username);
            formik.setFieldValue('type', formik.values.type);
          }}
        />

        {formik?.values?.customerId && (
          <div className="flex gap-4 w-full">
            <FormikInputGroup
              name="customerName"
              formik={formik}
              label="Customer Name"
              readOnly
              fullWidth
            />
            <FormikInputGroup
              name="customerPhone"
              formik={formik}
              label="Customer Phone"
              readOnly
              fullWidth
            />
            <FormikSelectGroup
              options={generateOptions({
                array: location?.docs ?? [],
                labelField: 'name',
                valueField: '_id',
              })}
              label="Location"
              name="location"
              formik={formik}
              isDisabled
            />
            <FormikInputGroup
              value={creditbook?.docs?.[0]?.balance ? creditbook?.docs[0].balance : 0}
              label="Credit balance"
              name="credit_balance"
              formik={formik}
              isDisabled
              readOnly
              fullWidth
            />
          </div>
        )}

        {formik?.values?.location && formik?.values?.customerId && (
          <FormikProvider value={formik}>
            <FieldArray
              name="products"
              render={arrayHelpers => (
                <div className="flex flex-col gap-3">
                  <div>
                    <ProductSelect formik={formik} arrayHelpers={arrayHelpers} pageType="sale" />
                  </div>
                  <div className="w-full overflow-x-auto">
                    <table className=" ">
                      <TableHeader
                        headers={[
                          { name: '' },
                          { name: 'photo' },
                          { name: 'Product' },
                          { name: 'qty', style: { width: '40px' } },
                          { name: 'MRP', style: { width: '65px' } },
                          { name: 'Selling Price' },
                          { name: 'Discount' },
                          { name: 'Total Discount' },
                          { name: 'Tax type' },
                          { name: 'Tax Rate', style: { width: '40px' } },
                          { name: 'Tax AMT', style: { width: '40px' } },
                          { name: 'Total Tax' },
                          { name: 'Total Price' },
                        ]}
                      />
                      <tbody>
                        {formik.values.products.map((ele, index) => (
                          <ConsumeSelectProductRow
                            key={index}
                            formik={formik}
                            element={ele}
                            index={index}
                            arrayHelpers={arrayHelpers}
                            pageType="sale"
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            />
          </FormikProvider>
        )}

        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default Consume;
