import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductPurchaseOrders,
  getProductPurchaseOrders,
  resetProductPurchaseOrderData,
} from '../../app/reducers/ProductPurchaseOrder/productPurchaseOrderSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import moment from 'moment';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import { useFormik } from 'formik';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { useNavigate } from 'react-router-dom';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { generateBarcodeDataByQty } from '../../utils/generateBarcodeData';
import { ClipLoader } from 'react-spinners';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import PaymentModel from './PaymentModel';

const ViewProductPurchaseOrder = () => {
  const [page, setPage] = useState(1);
  const { productPurchaseOrder } = useSelector(getProductPurchaseOrders);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [paymentModel, setPaymentModel] = useState(false);
  const [editData, setData] = useState(null);
  const { baseUrl } = useSelector(getMediaLibrary);
  const { location } = useSelector(getLocation);
  const { vendor } = useSelector(getVendors);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetProductPurchaseOrderData());
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      location: '',
      manufacturer: '',
      status: '',
      from: moment().startOf('day').valueOf(),
      to: moment().endOf('day').valueOf(),
    },
    onSubmit: async values => {
      let filteringData = {
        createdAt: {
          $gt: values?.from,
          $lt: values?.to,
        },
        populate: true,
        page,
      };

      if (values?.location) {
        filteringData['location._id'] = values?.location;
      }
      if (values?.manufacturer) {
        filteringData['manufacturer._id'] = values?.manufacturer;
      }
      if (values?.status) {
        filteringData['status'] = values?.status;
      }

      await fetchProductPurchaseOrder(filteringData);
    },
  });

  const fetchProductPurchaseOrder = async data => {
    setLoading(true);
    let string = QueryString.stringify(data);
    let resp = await authAxiosInstance.get(`/productPurchaseOrder/po/number?${string}`);
    console.log(resp);
    if (resp?.data?.data) {
      setProductData(resp?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    let filteringData = {
      createdAt: {
        $gt: formik?.values?.from,
        $lt: formik?.values?.to,
      },
      populate: true,
      page,
    };

    if (formik?.values?.location) {
      filteringData['location._id'] = formik?.values?.location;
    }
    if (formik?.values?.manufacturer) {
      filteringData['manufacturer._id'] = formik?.values?.manufacturer;
    }
    if (formik?.values?.status) {
      filteringData['status'] = formik?.values?.status;
    }
    dispatch(fetchLocations());
    dispatch(fetchVendors());
    fetchProductPurchaseOrder(filteringData);
  }, [page]);

  // const columns = [
  //   {
  //     Header: 'Select',
  //     Cell: ({ row, data }) => (
  //       <input
  //         type="checkbox"
  //         checked={selectedRows.map(ele => ele.id).includes(row.original._id)}
  //         onChange={e => {
  //           e.preventDefault();

  //           let obj = {
  //             productDetail: {
  //               productName: row?.original?.product?.name,
  //               masterSku: row?.original?.product?.masterSku,
  //             },
  //             quantity: row?.original?.quantity,
  //             id: row?.original?._id,
  //           };
  //           if (e.target.checked) {
  //             setSelectedRows([...selectedRows, obj]);
  //           } else {
  //             const data = selectedRows.filter(item => item.id !== row.original._id);
  //             setSelectedRows(data);
  //           }
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     Header: 'SRNO',
  //     Cell: prop => {
  //       const { data, row } = prop;
  //       return row.index + productPurchaseOrder.pagingCounter;
  //     },
  //   },
  //   {
  //     Header: 'Date',
  //     Cell: ({ row }) => {
  //       return row?.original?.createdAt
  //         ? moment(row?.original?.createdAt).format('DD/MM/YYYY')
  //         : 'No Date Found';
  //     },
  //   },
  //   {
  //     Header: 'PO Number',
  //     Cell: ({ row }) => {
  //       return (
  //         <div
  //           style={{
  //             textDecoration: 'underline',
  //             color: 'blue',
  //             cursor: 'pointer',
  //           }}
  //           onClick={async () => {
  //             await navigate(`/product/viewChallanByPurchaseOrder/${row?.original?._id}`);
  //           }}
  //         >
  //           {row?.original?.poNumber}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     Header: 'Photo',
  //     Cell: prop => {
  //       const { data, row } = prop;
  //       return (
  //         <>
  //           <div style={{ position: 'relative' }}>
  //             {_.isEmpty(row?.original?.product?.photo) ? (
  //               'No Image'
  //             ) : (
  //               <img
  //                 src={`${baseUrl}${row.original?.product?.photo}`}
  //                 style={{ width: '100px', height: '100px' }}
  //               />
  //             )}
  //           </div>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     Header: 'Product',
  //     accessor: 'product.masterSku',
  //   },
  //   {
  //     Header: 'Location',
  //     accessor: 'location.name',
  //   },
  //   {
  //     Header: 'manufacturer',
  //     accessor: 'manufacturer.companyName',
  //   },
  //   {
  //     Header: 'Status',
  //     accessor: 'status',
  //   },
  //   {
  //     Header: 'Total Ordered',
  //     accessor: 'quantity',
  //   },
  //   {
  //     Header: 'Remaining Ordered',
  //     accessor: 'remaining',
  //   },
  //   {
  //     Header: 'Notes',
  //     accessor: 'notes',
  //   },
  // ];

  const columns = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + productData.pagingCounter;
      },
    },
    // {
    //   Header: 'Date',
    //   Cell: ({ row }) => {
    //     return row?.original?.createdAt
    //       ? moment(row?.original?.createdAt).format('DD/MM/YYYY')
    //       : 'No Date Found';
    //   },
    // },
    {
      Header: 'PO Number',
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={async () => {
              await navigate(`/product/viewProductPurchaseOrder/${row?.original?._id}`);
            }}
          >
            {row?.original?._id}
          </div>
        );
      },
    },
    {
      Header: 'manufacturer',
      accessor: 'manufacturer.companyName',
    },
    {
      Header: 'location',
      accessor: 'location.name',
    },
    {
      Header: 'totalAmount',
      Cell: ({ row }) => {
        return <div>{Math.ceil(row?.original?.totalAmount)}</div>;
      },
    },
    {
      Header: 'Remaining Amount',
      Cell: ({ row }) => {
        return (
          <div className="text-blue-600">
            {Math.ceil(row?.original?.totalAmount) -
              row?.original?.payments.reduce((acc, item) => acc + item.amount, 0)}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div
            className="flex flex-row gap-3"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <PrimaryButton
              onClick={e => {
                e.stopPropagation();
                setData(row.original);
                setPaymentModel(true);
              }}
            >
              Pay Amount
            </PrimaryButton>
            <PrimaryButton
              onClick={async e => {
                e.stopPropagation();
                navigate(`/product/viewChallanByPurchaseOrder/${row?.original?._id}`);
              }}
            >
              View Dispatch
            </PrimaryButton>
          </div>
        );
      },
    },
    // {
    //   Header: 'Status',
    //   accessor: 'status',
    // },
    // {
    //   Header: 'Total Ordered',
    //   accessor: 'quantity',
    // },
    // {
    //   Header: 'Remaining Ordered',
    //   accessor: 'remaining',
    // },
    // {
    //   Header: 'Notes',
    //   accessor: 'notes',
    // },
  ];

  const dataMemoization = useMemo(() => productData?.docs ?? [], [productData]);
  const columnsMemoization = useMemo(() => columns, [columns]);

  const debouncingSearch = useCallback(
    _.debounce(search => {
      let filteringData = {
        createdAt: {
          $gt: formik?.values?.from,
          $lt: formik?.values?.to,
        },
        'location._id': formik?.values?.location,
        'manufacturer._id': formik?.values?.manufacturer,
        status: formik?.values?.status,
        populate: true,
        search: search,
        page,
      };
      dispatch(fetchProductPurchaseOrders(filteringData));
    }, 300),
    []
  );
  return (
    <PageWithCard heading="View Product Purchase Order">
      <PaymentModel
        isOpen={paymentModel}
        setIsOpen={setPaymentModel}
        data={editData}
        setProductData={setProductData}
        fetchOrders={fetchProductPurchaseOrder}
        formikData={formik}
        type="purchaseOrder"
      />
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <FormikSelectGroup
          label="Manufacturer"
          name="manufacturer"
          formik={formik}
          options={generateOptions({
            array: vendor?.docs ?? [],
            labelField: 'companyName',
            valueField: '_id',
          })}
        />
        <FormikSelectGroup
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: 'name',
            valueField: '_id',
          })}
          label="Location"
          name="location"
          formik={formik}
        />
        <FormikSelectGroup
          options={generateOptions({
            array: [
              { label: 'Direct', value: 'direct' },
              { label: 'Pending', value: 'pending' },
              { label: 'Completed', value: 'completed' },
              { label: 'Settled', value: 'settled' },
              { label: 'Incomplete', value: 'incomplete' },
            ],
            valueField: 'value',
            labelField: 'label',
          })}
          label="Status"
          name="status"
          formik={formik}
        />

        <FormikInputDateGroup name="from" label="From" formik={formik} />
        <FormikInputDateGroup name="to" label="To" formik={formik} />

        <div className="mb-2">
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      {selectedRows?.length > 0 && (
        <div className="mb-3">
          <PrimaryButton onClick={() => generateBarcodeDataByQty(selectedRows)} type="button">
            Print
          </PrimaryButton>
        </div>
      )}
      <TableWithHeadingAndGlobalSearch
        loading={loading}
        columns={columnsMemoization}
        data={dataMemoization}
        searchFunction={value => {
          debouncingSearch(value);
        }}
      />
      <PaginationClassic paginationDetails={productData} setPage={setPage} />
    </PageWithCard>
  );
};

export default ViewProductPurchaseOrder;
