import React, { useEffect, useState } from 'react';
import PageWithCard from '../../../components/infrastructure/PageWithCard';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCreditbooks, getCreditbook } from '../../../app/reducers/Creditbook/creditbookSlice';
import PrimaryButton from '../../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../../components/Table/TableWithHeadingAndSearch';
import ReceiveAmountModal from './creditBookModals/ReceiveAmountModal';
import moment from 'moment/moment';
import { fetchCustomer, getCustomers } from '../../../app/reducers/Users/CustomerSlice';
import { PropagateLoader } from 'react-spinners';
import AddCreditNoteModal from './creditBookModals/AddCreditNoteModal';
import AddDebitNoteModal from './creditBookModals/AddDebitNoteModal';
import PayAmountModal from './creditBookModals/PayAmountModal';
import { fetchVendor, getVendors } from '../../../app/reducers/Users/VendorSlice';

const VendorCreditbook = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const { creditbook, loading: creditbookLoading } = useSelector(getCreditbook);
  const { elementEditData: customerData, loading: customerLoading } = useSelector(getVendors);

  const [openModal, setOpenModal] = useState({
    receiveAmount: false,
    addCreditNote: false,
    payAmount: false,
    addDebitNote: false,
  });

  useEffect(() => {
    dispatch(fetchVendor({ _id: customerId }));
    dispatch(
      fetchCreditbooks({
        populate: true,
        'user._id': customerId,
      })
    );
  }, [customerId]);

  const columns = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format('DD/MM/YYYY - HH:mm');
      },
    },

    {
      Header: 'Amount',
      Cell: ({ row }) => {
        if (row.original.transaction_type == 'debit') {
          return <span className="text-red-600 font-semibold"> -{row.original.amount} </span>;
        } else if (row.original.transaction_type == 'credit') {
          return <span className="text-green-600 font-semibold"> +{row.original.amount} </span>;
        }
      },
      accessor: 'amount',
    },
    {
      Header: 'Balance',
      accessor: 'balance',
    },
    {
      Header: 'Note',
      accessor: 'notes',
    },

    {
      Header: 'type',
      Cell: ({ row }) => {
        if (row.original.transaction_type == 'debit') {
          return <span className="text-red-600 font-semibold">Debit ( - ) </span>;
        } else if (row.original.transaction_type == 'credit') {
          return <span className="text-green-600 font-semibold">Credit ( + )</span>;
        }
      },
      accessor: 'transaction_type',
    },
  ];

  const toggleModal = (modal, boolean) => {
    setOpenModal(prev => ({ ...prev, [modal]: boolean }));
  };

  return (
    <PageWithCard heading={customerData?.companyName}>
      <ReceiveAmountModal
        isOpen={openModal.receiveAmount}
        setIsOpen={boolean => toggleModal('receiveAmount', boolean)}
        data={customerData}
      />
      <AddCreditNoteModal
        isOpen={openModal.addCreditNote}
        setIsOpen={boolean => toggleModal('addCreditNote', boolean)}
        data={customerData}
      />
      <AddDebitNoteModal
        isOpen={openModal.addDebitNote}
        setIsOpen={boolean => toggleModal('addDebitNote', boolean)}
        data={customerData}
      />
      <PayAmountModal
        isOpen={openModal.payAmount}
        setIsOpen={boolean => toggleModal('payAmount', boolean)}
        data={customerData}
      />

      <div className="flex flex-col gap-3">
        <div className="flex gap-3">
          <PrimaryButton
            onClick={e => {
              e.stopPropagation();
              toggleModal('receiveAmount', true);
            }}
          >
            Receive Amount
          </PrimaryButton>
          <PrimaryButton
            onClick={e => {
              e.stopPropagation();
              toggleModal('payAmount', true);
            }}
          >
            Pay Amount
          </PrimaryButton>
          <PrimaryButton
            onClick={e => {
              e.stopPropagation();
              toggleModal('addCreditNote', true);
            }}
          >
            Add Credit Note
          </PrimaryButton>
          <PrimaryButton
            onClick={e => {
              e.stopPropagation();
              toggleModal('addDebitNote', true);
            }}
          >
            Add Debit Note
          </PrimaryButton>
        </div>
        <div className="flex flex-col md:flex-row gap-6 m-2 font-semibold">
          <h2>
            Balance: <span>{creditbook?.docs?.[0]?.balance ? creditbook?.docs[0].balance : 0}</span>
          </h2>
        </div>
        {creditbookLoading ? (
          <div className="flex items-center justify-center ">
            <PropagateLoader />
          </div>
        ) : (
          <TableWithHeadingAndSearch
            heading={'Credit History'}
            data={creditbook?.docs ?? []}
            columns={columns}
          />
        )}
      </div>
    </PageWithCard>
  );
};

export default VendorCreditbook;
