import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import { useFormik } from "formik";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { generateOptions } from "../../utils/Utils";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import ViewPendingInventoryModal from "./ViewPendingInventoryModal";
import TotalCommittedStockDetailModal from "./TotalCommittedStockDetailModal";

const InventoryPage = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryPageData, setInventoryPageData] = useState({});
  const [totalCommittedStockProductId, setTotalCommittedStockProductId] =
    useState(null);
  const [isTotalCommittedStockModalOpen, setIsTotalCommittedStockModalOpen] =
    useState(false);

  const { baseUrl } = useSelector(getMediaLibrary);
  const { location, loading } = useSelector(getLocation);
  const locationInfoId = JSON.parse(localStorage.getItem("locationInfoId"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { location: locationInfoId ?? [] },
    onSubmit: (values) => {},
  });

  useEffect(() => {
    dispatch(fetchLocations());
    getInventoryPageData();
  }, [JSON.stringify(formik.values.location), page]);

  const getInventoryPageData = async (search = "") => {
    try {
      setIsLoading(true);
      const data = {
        filteredLocationArray: formik?.values?.location ?? [],
        page,
      };
      if (search) {
        data["search"] = search;
      }
      const filterQuery = QueryString.stringify(data);
      const response = await authAxiosInstance.get(
        `productInventory/page?${filterQuery}`
      );
      if (response) {
        setInventoryPageData(response?.data?.data);
      } else {
        setInventoryPageData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Error fetching inventory");
    } finally {
      setIsLoading(false);
    }
  };

  const [pendingDataModalOpen, setPendingDataModalOpen] = useState(false);
  const [pendingDataModalData, setPendingDataModalData] = useState({});

  const columns = [
    {
      Header: "SRNo",
      Cell: ({ row }) => {
        return row.index + inventoryPageData.pagingCounter;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: "relative" }}>
              {_.isEmpty(row?.original?.product?.photo) ? (
                "No Image"
              ) : (
                <img
                  src={`${baseUrl}${row.original?.product?.photo}`}
                  style={{ width: "100px", height: "100px" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "Product",
      accessor: "product.name",
    },
    {
      Header: "Master Sku",
      accessor: "product.masterSku",
    },
    {
      Header: "Cost Price",
      accessor: "product.costPrice",
    },
    {
      Header: "Received",
      accessor: "currentReceivedData",
    },
    {
      Header: "Damaged",
      accessor: "currentDamagedData",
    },
    {
      Header: "Total Committed Stock",
      Cell: ({ row }) => {
        return (
          <div
            className="text-blue-500 underline cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setIsTotalCommittedStockModalOpen(true);
              setTotalCommittedStockProductId(row.original.product._id);
            }}
          >
            {row?.original?.saleOrder?.totalUpdatedCurrentCommittedStock}
          </div>
        );
      },
      accessor: "saleOrder.totalUpdatedCurrentCommittedStock",
    },
    {
      Header: "Location",
      accessor: "location.name",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div>
            <PrimaryButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setPendingDataModalOpen(true);
                setPendingDataModalData({
                  product: row.original?.product?._id,
                  location: row.original.location._id,
                });
              }}
            >
              View
            </PrimaryButton>
          </div>
        );
      },
    },
  ];

  const columnsMemoization = useMemo(() => columns ?? [], [columns]);
  const dataMemoization = useMemo(
    () => inventoryPageData?.docs ?? [],
    [inventoryPageData]
  );
  const debouncedSearch = useCallback(
    _.debounce((search) => {
      getInventoryPageData(search);
    }, 300),
    [inventoryPageData?.docs]
  );
  return (
    <PageWithCard heading="Inventory">
      <ViewPendingInventoryModal
        data={pendingDataModalData}
        isOpen={pendingDataModalOpen}
        setIsOpen={setPendingDataModalOpen}
      />
      <TotalCommittedStockDetailModal
        modalOpen={isTotalCommittedStockModalOpen}
        setModalOpen={setIsTotalCommittedStockModalOpen}
        productId={totalCommittedStockProductId}
      />
      <form onSubmit={formik.handleSubmit}>
        <FormikMultiSelect
          label="Selected Location"
          name="location"
          formik={formik}
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: "name",
            valueField: "_id",
          })}
        />
      </form>
      <>
        {}
        <div className="mt-3">
          <TableWithHeadingAndGlobalSearch
            data={dataMemoization}
            columns={columnsMemoization}
            loading={loading || isLoading}
            searchFunction={debouncedSearch}
          />
          <PaginationClassic
            paginationDetails={inventoryPageData}
            setPage={setPage}
          />
        </div>
      </>
    </PageWithCard>
  );
};

export default InventoryPage;
