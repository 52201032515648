import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVendor, fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { Edit2, Trash } from 'react-feather';
import { useNavigate, Link } from 'react-router-dom';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';

const ViewVendor = () => {
  const [page, setPage] = useState(1);
  const { vendor, loading } = useSelector(getVendors);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchVendors({ page }));
  }, [page]);

  const columns = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: 'Vendor Name',
      Cell: ({ row }) => {
        return (
          <Link to={`credit/${row.original._id}`}>
            <div className="text-blue-600 underline cursor-pointer">
              {row?.original?.firstName + ' ' + row?.original?.lastName};
            </div>
          </Link>
        );
      },
    },
    {
      Header: 'username',
      accessor: 'username',
    },
    {
      Header: 'Company Name',
      accessor: 'companyName',
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Edit2
              color="blue"
              onClick={e => {
                navigate(`/users/edit-vendor/${row?.original?._id}`);
              }}
            />

            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteVendor({ id: row?.original?._id }));
                await dispatch(fetchVendors());
              }}
            />
          </div>
        );
      },
    },
  ];
  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(() => (vendor.docs ? vendor.docs : []), [vendor.docs]);

  return (
    <PageWithCard heading="View Vendor">
      {loading ? (
        <ClipLoader />
      ) : (
        <>
          <TableWithHeadingAndSearch heading="Vendors" columns={columnsMemo} data={dataMemo} />
          <PaginationClassic setPage={setPage} paginationDetails={vendor} />
        </>
      )}
    </PageWithCard>
  );
};

export default ViewVendor;
