import { createSlice } from "@reduxjs/toolkit"
import { generateStateSingle } from "../../generators/generateState"
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";

const SLICE_NAME = 'group';

export const fetchGroups = fetchData.generateThunk(SLICE_NAME,SLICE_NAME);
export const fetchGroup = fetchEditData.generateThunk(SLICE_NAME,SLICE_NAME);
export const createGroup = createData.generateThunk(SLICE_NAME,SLICE_NAME);
export const editGroup = editData.generateThunk(SLICE_NAME,SLICE_NAME);
export const deleteGroup = deleteData.generateThunk(SLICE_NAME,SLICE_NAME);

const groupSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers :{
        ...fetchData.generateExtraReducers(fetchGroups ,SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchGroup, SLICE_NAME),
        ...createData.generateExtraReducers(createGroup, SLICE_NAME),
        ...editData.generateExtraReducers(editGroup, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteGroup, SLICE_NAME),
    }
})

export const getGroups = (state)=> state.group;

export default groupSlice.reducer;