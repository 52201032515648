import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useNavigate, useParams } from "react-router-dom";
import { Chrono } from "react-chrono";
import { useDispatch } from "react-redux";
import moment from "moment";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { ClipLoader } from "react-spinners";
import { ArrowLeftCircle } from "react-feather";

const ViewLeadHistory = () => {
  const { uniqNo } = useParams();
  const [items, setItems] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const computeItems = () => {
    try {
      setIsLoading(true);
      const mappedItems = historyData.map((ele) => {
        const numbersCon = ele.contactNumbers
          .map((contactNumber) => contactNumber)
          .join(", ");

        return {
          title: moment(ele.createdAt).format("DD/MM/YYYY"),
          cardTitle: `Phone : ${numbersCon}, 
          Name : ${ele.name}, 
          Meeting Date : ${
            ele?.meetingDate
              ? moment(ele?.meetingDate).format("DD/MM/YYYY")
              : "Not Yet"
          }, 
          Meeting Time: ${
            ele?.meetingTime
              ? moment(ele?.meetingTime).format("hh:mm a")
              : "Not Yet"
          },
           Call Schedule Date : ${
             ele?.callScheduleDate
               ? moment(ele?.callScheduleDate).format("DD/MM/YYYY")
               : "Not Schedule"
           }, 
          Call Schedule Time: ${
            ele?.callScheduleTime
              ? moment(ele?.callScheduleTime).format("hh:mm a")
              : "Not Schedule"
          }
          `,

          // cardSubtitle: ele.notes ? (
          //   <pre className="break-words">{ele.notes}</pre>
          // ) : (
          //   "No notes available"
          // ),
          cardSubtitle: ele.notes ? (
            <div className="whitespace-pre-wrap break-words">{ele.notes}</div>
          ) : (
            "No notes available"
          ),
          cardDetailedText: ele.editSummary ? (
            <pre>{ele.editSummary}</pre>
          ) : (
            "No summary available"
          ),
        };
      });
      setItems(mappedItems);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  };
  console.log("items", items);
  const computeHistory = async () => {
    try {
      const payload = {
        leadNumber: uniqNo,
        sort: { createdAt: -1 },
      };
      const string = QueryString.stringify(payload);
      const resp = await authAxiosInstance.get(`/lead?${string}`);
      console.log("resp", resp?.data?.data?.docs);
      setHistoryData(resp?.data?.data?.docs);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    computeHistory();
  }, [uniqNo]);

  useEffect(() => {
    if (historyData?.length > 0) {
      computeItems();
    }
  }, [historyData]);
  return (
    <div className="relative">
      <PageWithCard heading={`Lead : ${uniqNo}`}>
        {isLoading ? (
          <ClipLoader />
        ) : (
          <div style={{ width: "100%", height: "100%" }}>
            {items.length > 0 ? (
              <Chrono
                items={items}
                mode="VERTICAL"
                slideShow
                slideItemDuration={4000}
                scrollable={{ scrollbar: false }}
                key={items.length}
              />
            ) : (
              <p>No history available.</p>
            )}
          </div>
        )}
        <div className="absolute right-10 top-10">
          <button
            style={{
              color: "#6366F1",
            }}
            onClick={(e) => {
              navigate(`/lead/view`);
            }}
          >
            <ArrowLeftCircle size={24} />
          </button>
        </div>
      </PageWithCard>
    </div>
  );
};

export default ViewLeadHistory;
