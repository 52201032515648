export const GENDER = [
  { label: 'male', value: 'male' },
  { label: 'female', value: 'female' },
];
export const ROLES = [
  { label: 'Admin', value: 'admin' },
  { label: 'Admin', value: 'org_admin' },
  { label: 'Product Entity', value: 'product_entity' },
  { label: 'Inventory Entity', value: 'invenotory_entity' },
];

export const VOLUME = [
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' },
];
export const FORM = [
  { label: 'Liquid', value: 'LIQ' },
  { label: 'Powder', value: 'POW' },
  { label: 'SaltFree', value: 'SF' },
  { label: 'Press Cake', value: 'PC' },
  { label: 'Granules', value: 'GR' },
  { label: 'Crystal', value: 'CR' },
];

export const TSHIRT_SIZE = [
  { label: 'S', value: 'S' },
  { label: 'M', value: 'M' },
  { label: 'L', value: 'L' },
  { label: 'XL', value: 'XL' },
  { label: 'Xxl', value: 'Xxl' },
  { label: 'XXxl', value: 'XXxl' },
];
export const FOOD_PREFERENCE = [
  { label: 'Veg', value: 'Veg' },
  { label: 'Non veg', value: 'Non veg' },
  { label: 'Jain', value: 'Jain' },
];
export const MUSIC_PREFERENCE = [
  { label: 'Rock', value: 'Rock' },
  { label: 'Jazz', value: 'Jazz' },
  { label: 'Bollywood party', value: 'Bollywood party' },
  { label: 'Sufi', value: 'Sufi' },
  { label: 'Ghazal', value: 'Ghazal' },
];
export const CREW_MEMBERS = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];
export const TITLE = [
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Ms.', value: 'Ms.' },
  { label: 'Mrs.', value: 'Mrs.' },
];
export const PAYMENT_METHOD = [
  { label: 'Cash', value: 'cash' },
  // { label: "UPI", value: "upi" },
  { label: 'Credit', value: 'credit' },
];
export const PAYMENT_TYPE = [
  { label: 'Credit(+)', value: 'credit' },
  { label: 'Debit(-)', value: 'debit' },
];

export const DISCOUNT_TYPES = [
  { label: 'Percentage', value: 'percentage' },
  { label: 'Amount', value: 'amount' },
];

export const Tax_TYPES = [
  { label: 'Inc.', value: 'inc' },
  { label: 'Exc.', value: 'exc' },
];

export const LEAD_MEETING_SCHEDULE = [
  { label: 'No', value: 'no' },
  { label: 'Yes', value: 'yes' },
];

export const LEAD_FILTER_OPTION = [
  { label: 'No', value: 'no' },
  { label: 'Yes', value: 'yes' },
];

export const CONSUME_TYPES = [
  { label: 'Challan', value: 'challan' },
  { label: 'Consumption', value: 'consumption' },
];
