import React, { useState, useEffect, useRef } from "react";
import Validator from "simple-react-validator"; /* React Validator */
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import { useForceUpdate } from "../../Common/Function";
import AuthImage from "../../images/auth-image.jpeg";
import AuthDecoration from "../../images/auth-decoration.png";
import {
  checkJwt,
  getAuth,
  postLogin,
} from "../../app/reducers/Auth/authSlice";
import { useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { object, string } from "yup";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
const computeFrom = (location) => {
  const from = location?.state?.from;
  if (from && from !== "/logout") {
    return from;
  } else {
    return "/";
  }
};
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = computeFrom(location);

  const forceUpdate = useForceUpdate();
  const { loading, jwt, error } = useSelector(getAuth);
  // const [allValues, setValues] = useState({ phone: "", password: "" });
  const formik = useFormik({
    initialValues: {
      username: "91",
      password: "",
    },
    validationSchema: object({
      username: string().required(),
      password: string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      dispatch(postLogin(values)).then((res) => {
        if (res.payload.success) {
          navigate(from, { replace: true });
          resetForm();
        }
      });
    },
  });

  useEffect(() => {
    console.log("========== from in Login js==============");
    console.log(from);
    console.log("========== location in Login js==============");
    console.log(location);
    dispatch(checkJwt());
    if (jwt) {
      navigate(from);
    }
  }, [jwt]);

  return (
    <>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <main className="bg-white">
          <div className="relative md:flex">
            <div className="md:w-1/2">
              <div className="min-h-screen h-full flex flex-col after:flex-1">
                <div className="flex-1">
                  <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                    {/* <div className="flex justify-center items-center">
                      <img
                        src="https://res.cloudinary.com/blinklink-solutions/image/upload/v1562090581/60292_cr_cr_bmfn3c.png"
                        className="w-[60px]"
                      />
                      <p className="text-xl">Blinklink Solutions PVT LTD</p>
                    </div> */}
                  </div>
                </div>
                {location?.state?.message && (
                  <div className="text-center ">
                    <p className="bg-green-300 px-3 py-1 rounded-md text-green-900 w-[50%] mx-auto ">
                      {location.state.message}
                    </p>
                  </div>
                )}
                <div className="w-full md:w-1/2 mx-auto px-4 py-8">
                  <h1 className="text-3xl text-slate-800 font-bold mb-6">
                    Login
                  </h1>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="space-y-4">
                      <p className="text-red-500 text-xs text-center">
                        {error}
                      </p>
                      <FormikPhoneInputGroup
                        name="username"
                        required
                        formik={formik}
                        label="Phone"
                      />
                      <FormikInputGroup
                        label="Password"
                        required
                        name="password"
                        type="password"
                        formik={formik}
                      />
                      <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                      </div>
                      <div className="border border-zinc-300 mt-6" />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
              aria-hidden="true"
            >
              <img
                className="object-cover object-center w-full h-full"
                src={AuthImage}
                width="760"
                height="1024"
                alt="Authentication"
              />
              <img
                className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block"
                src={AuthDecoration}
                width="218"
                height="224"
                alt="Authentication decoration"
              />
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Login;
