import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductPurchaseOrders,
  getProductPurchaseOrders,
  resetProductPurchaseOrderData,
} from '../../app/reducers/ProductPurchaseOrder/productPurchaseOrderSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import moment from 'moment';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import { useFormik } from 'formik';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { generateBarcodeDataByQty } from '../../utils/generateBarcodeData';
import { ClipLoader } from 'react-spinners';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import PaymentModel from './PaymentModel';

const ViewPurchaseOrderByPoNo = () => {
  const [page, setPage] = useState(1);
  const { productPurchaseOrder } = useSelector(getProductPurchaseOrders);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const { baseUrl } = useSelector(getMediaLibrary);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { poNumber } = useParams();
  console.log(poNumber, 'PoNumber');
  useEffect(() => {
    return () => {
      dispatch(resetProductPurchaseOrderData());
    };
  }, []);

  useEffect(() => {
    let filteringData = {
      poNumber: poNumber,
      populate: true,
      page,
    };
    dispatch(fetchProductPurchaseOrders(filteringData));
  }, [page, poNumber]);

  const columns = [
    {
      Header: 'Select',
      Cell: ({ row, data }) => (
        <input
          type="checkbox"
          checked={selectedRows.map(ele => ele.id).includes(row.original._id)}
          onChange={e => {
            e.preventDefault();

            let obj = {
              productDetail: {
                productName: row?.original?.product?.name,
                masterSku: row?.original?.product?.masterSku,
              },
              quantity: row?.original?.quantity,
              id: row?.original?._id,
            };
            if (e.target.checked) {
              setSelectedRows([...selectedRows, obj]);
            } else {
              const data = selectedRows.filter(item => item.id !== row.original._id);
              setSelectedRows(data);
            }
          }}
        />
      ),
    },
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + productPurchaseOrder.pagingCounter;
      },
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    {
      Header: 'PO Number',
      Cell: ({ row }) => {
        return (
          <div
            style={{
              color: 'blue',
              cursor: 'pointer',
            }}
          >
            {row?.original?.poNumber}
          </div>
        );
      },
    },
    {
      Header: 'Photo',
      Cell: prop => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: 'relative' }}>
              {_.isEmpty(row?.original?.product?.photo) ? (
                'No Image'
              ) : (
                <img
                  src={`${baseUrl}${row.original?.product?.photo}`}
                  style={{ width: '100px', height: '100px' }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: 'Product',
      accessor: 'product.masterSku',
    },
    {
      Header: 'Location',
      accessor: 'location.name',
    },
    {
      Header: 'manufacturer',
      accessor: 'manufacturer.companyName',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Total Ordered',
      accessor: 'quantity',
    },
    {
      Header: 'Remaining Ordered',
      accessor: 'remaining',
    },
    {
      Header: 'Notes',
      accessor: 'notes',
    },
  ];

  const dataMemoization = useMemo(() => productPurchaseOrder?.docs ?? [], [productPurchaseOrder]);
  const columnsMemoization = useMemo(() => columns, [columns]);

  const debouncingSearch = useCallback(
    _.debounce(search => {
      let filteringData = {
        populate: true,
        search: search,
        page,
      };
      dispatch(fetchProductPurchaseOrders(filteringData));
    }, 300),
    []
  );
  return (
    <PageWithCard heading="View Product Purchase Order">
      {selectedRows?.length > 0 && (
        <div className="mb-3">
          <PrimaryButton onClick={() => generateBarcodeDataByQty(selectedRows)} type="button">
            Print
          </PrimaryButton>
        </div>
      )}
      <TableWithHeadingAndGlobalSearch
        loading={loading}
        columns={columnsMemoization}
        data={dataMemoization}
        searchFunction={value => {
          debouncingSearch(value);
        }}
      />
      <PaginationClassic paginationDetails={productPurchaseOrder} setPage={setPage} />
    </PageWithCard>
  );
};

export default ViewPurchaseOrderByPoNo;
