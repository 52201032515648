import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import moment from "moment";

const ViewDispatchedSaleOrder = () => {
  const { saleOrderId } = useParams();
  const [dispatchData, setDispatchData] = useState({});

  const navigate = useNavigate();

  async function fetchDispatch() {
    const string = QueryString.stringify({
      populate: true,
      sale_order_ref: saleOrderId,
    });
    const fetchDispatchResp = await authAxiosInstance.get(
      `/dispatch?${string}`
    );
    console.log(fetchDispatchResp);
    setDispatchData(fetchDispatchResp?.data?.data);
  }

  useEffect(() => {
    fetchDispatch();
  }, [saleOrderId]);

  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row?.original?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      Header: "Dispatch Number",
      Cell: ({ row }) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/dispatch/view/${row?.original?.dispatch_number}`);
            }}
            className="text-blue-500 cursor-pointer underline"
          >
            {row?.original?.dispatch_number}
          </div>
        );
      },
    },
    {
      Header: "Quantity",
      Cell: ({ row }) => {
        const totalQty = row?.original?.products?.reduce((acc, crr) => {
          return acc + crr.quantity;
        }, 0);
        return totalQty;
      },
    },
  ];

  const dataMemo = useMemo(
    () => (dispatchData?.docs ? dispatchData.docs : []),
    [dispatchData]
  );
  const colsMemo = useMemo(() => columns ?? [], [columns]);

  return (
    <PageWithCard heading={dispatchData?.docs?.[0]?.saleOrder?.saleOrderNumber}>
      <TableWithHeadingAndSearch data={dataMemo} columns={colsMemo} />
    </PageWithCard>
  );
};

export default ViewDispatchedSaleOrder;
