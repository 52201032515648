import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { generateOptions } from '../../utils/Utils';
import ProductPurchaseOrderContainer from './ProductPurchaseOrderContainer';
import { fetchProducts, getProducts } from '../../app/reducers/Product/productSlice';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import { ClipLoader } from 'react-spinners';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import TableHeader from '../../components/Table/TableHeader';
import QueryString from 'qs';
import { X } from 'react-feather';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { DISCOUNT_TYPES, Tax_TYPES } from '../../utils/dropdownOptions';
import FormikCalculationInput from '../../components/formik/FormikCalculationInput';
import { createCreditbook } from '../../app/reducers/Creditbook/creditbookSlice';

const ProductPurchaseOrder = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const { location } = useSelector(getLocation);
  const { vendor } = useSelector(getVendors);
  const { product } = useSelector(getProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendors());
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    initialValues: {
      manufacturer: '',
      location: '',
      notes: '',
      products: [],
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      notes: Yup.string(),
      products: Yup.array().of(
        Yup.object({
          product: Yup.string().required(),
          currentReceive: Yup.number().min(1, 'please add atleast 1 Quantity').required(),
        })
      ),
    }),
    onSubmit: async values => {
      if (values?.products?.length > 0) {
        try {
          const productsDataArr = values?.products.map(product => ({
            ...product,
            manufacturer: values?.manufacturer,
            location: values?.location,
            notes: values?.notes,
            product: product?.product,
            quantity: Number(product?.currentReceive),
            totalAmount: Number(product?.totalAmount),
            taxAmount: Number(product?.taxAmount),
            finalPurchaseRate: Number(product?.finalPurchaseRate),
          }));

          const poResponse = await authAxiosInstance.post('productPurchaseOrder', {
            products: productsDataArr,
          });
          formik.resetForm();
        } catch (error) {
          console.log('onSubmit Error ProductPurchaseOrder for loop: ', error);
          toast.error('Error on Submit');
        }
      }
    },
  });

  return (
    <PageWithCard heading="Create Purchase Order">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <FormikSelectGroup
          label="Select Manufacturer "
          formik={formik}
          name="manufacturer"
          onChange={async selectedOption => {
            await dispatch(fetchProducts({ manufacturer: selectedOption?.value }));
            formik.setFieldValue('manufacturer', selectedOption?.value);
          }}
          options={generateOptions({
            array: vendor?.docs ?? [],
            valueField: '_id',
            labelField: 'companyName',
          })}
          required
        />
        <FormikSelectGroup
          label="Select Location"
          formik={formik}
          name="location"
          options={generateOptions({
            array: location?.docs ?? [],
            valueField: '_id',
            labelField: 'name',
          })}
          required
        />
        <FormikTextareaGroup name="notes" label="Notes" formik={formik} />

        {formik?.values?.manufacturer && (
          <FormikProvider value={formik}>
            <FieldArray
              name="products"
              render={arrayHelpers => (
                <div className="flex flex-col gap-3">
                  <div>
                    <FormikAsyncSelect
                      name={``}
                      label="Search Product"
                      formik={formik}
                      getOptions={async value => {
                        const string = QueryString.stringify({
                          search: value,
                          manufacturer: { $in: [formik.values.manufacturer] },
                        });
                        const product = await authAxiosInstance.get(`/product?${string}`);
                        const options = product?.data?.data?.docs?.map(ele => ({
                          label: `${ele.name} - ${ele.masterSku}`,
                          value: JSON.stringify(ele),
                        }));
                        return options;
                      }}
                      onChange={async selectedOption => {
                        const product = JSON.parse(selectedOption.value);
                        const checkExist = formik.values.products.findIndex(
                          prod => prod.product == product._id
                        );

                        if (checkExist !== -1) {
                          formik.setFieldValue(
                            `products.${checkExist}.currentReceive`,
                            formik.values.products[checkExist]?.currentReceive + 1
                          );
                          return toast.success('Receive quantity Increased by One');
                        }

                        arrayHelpers.push({
                          product: product?._id,
                          masterSku: product?.masterSku,
                          currentReceive: 0,
                          currentDamage: 0,
                          finalPurchaseRate: '',
                          taxType: 'exc',
                          discountType: 'percentage',
                          purchaseRate: product?.costPrice,
                        });
                      }}
                    />
                  </div>
                  <div className="w-full overflow-x-auto">
                    <table className="w-full">
                      <TableHeader
                        headers={[
                          {
                            name: 'select',
                          },
                          { name: 'Product' },
                          { name: 'Receive qty' },
                          { name: 'Purchase rate' },
                          { name: 'Discount Type' },
                          { name: 'Discount Rate' },
                          { name: 'Tax Rate' },
                          { name: 'Tax Type' },
                          { name: 'Tax Amount' },
                          { name: 'Final Purchase' },
                          { name: 'Total Amount' },
                        ]}
                      />
                      <tbody>
                        {formik.values.products.map((product, index) => (
                          <tr key={index} className="w-full">
                            <td className="px-2 text-center">
                              {/* <input
                                type="checkbox"
                                onChange={formik.handleChange}
                                name={`products.${index}.select`}
                                checked={product.select}
                              /> */}

                              <X
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </td>
                            <td className="px-2">{product.masterSku}</td>
                            <td className="px-2">
                              <FormikInputGroup
                                type="number"
                                style={{ width: '70px' }}
                                name={`products.${index}.currentReceive`}
                                formik={formik}
                              />
                            </td>

                            <td className="px-2  ">
                              <FormikInputGroup
                                type="number"
                                style={{ width: '80px' }}
                                name={`products.${index}.purchaseRate`}
                                formik={formik}
                              />
                            </td>
                            <td className="px-2  ">
                              <FormikSelectGroup
                                name={`products.${index}.discountType`}
                                formik={formik}
                                options={generateOptions({
                                  array: DISCOUNT_TYPES ?? [],
                                  labelField: 'label',
                                  valueField: 'value',
                                })}
                              />
                            </td>
                            <td className="px-2  ">
                              <FormikInputGroup
                                type="number"
                                style={{ width: '80px' }}
                                name={`products.${index}.discountRate`}
                                formik={formik}
                              />
                            </td>
                            <td className="px-2  ">
                              <FormikInputGroup
                                type="number"
                                name={`products.${index}.taxRate`}
                                formik={formik}
                              />
                            </td>
                            <td className="px-2" style={{ width: '110px' }}>
                              <FormikSelectGroup
                                name={`products.${index}.taxType`}
                                formik={formik}
                                options={generateOptions({
                                  array: Tax_TYPES ?? [],
                                  labelField: 'label',
                                  valueField: 'value',
                                })}
                              />
                            </td>
                            <td className="px-2  ">
                              <FormikCalculationInput
                                type="number"
                                name={`products.${index}.taxAmount`}
                                formik={formik}
                                value={
                                  formik.values.products[index].taxType === 'exc'
                                    ? (
                                        (formik.values.products[index].finalPurchaseRate *
                                          formik.values.products[index].taxRate) /
                                        100
                                      ).toFixed(2)
                                    : (
                                        (formik.values.products[index].finalPurchaseRate *
                                          formik.values.products[index].taxRate) /
                                        (100 + formik.values.products[index].taxRate)
                                      ).toFixed(2)
                                }
                              />
                            </td>
                            <td className="px-2">
                              <FormikCalculationInput
                                type="number"
                                name={`products.${index}.finalPurchaseRate`}
                                formik={formik}
                                value={
                                  formik.values.products[index].discountType === 'percentage'
                                    ? (
                                        formik.values.products[index].purchaseRate -
                                        (formik.values.products[index].purchaseRate *
                                          formik.values.products[index].discountRate) /
                                          100
                                      ).toFixed(2)
                                    : (
                                        formik.values.products[index].purchaseRate -
                                        formik.values.products[index].discountRate
                                      ).toFixed(2)
                                }
                                readOnly
                              />
                            </td>
                            <td className="px-2  ">
                              <FormikCalculationInput
                                type="number"
                                name={`products.${index}.totalAmount`}
                                formik={formik}
                                value={
                                  formik.values.products[index].taxType == 'exc'
                                    ? (
                                        formik.values.products[index].finalPurchaseRate *
                                          formik.values.products[index].currentReceive +
                                        formik.values.products[index].taxAmount *
                                          formik.values.products[index].currentReceive
                                      ).toFixed(2)
                                    : (
                                        formik.values.products[index].finalPurchaseRate *
                                        formik.values.products[index].currentReceive
                                      ).toFixed(2)
                                }
                                readOnly
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            />
          </FormikProvider>
        )}
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default ProductPurchaseOrder;
