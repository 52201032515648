import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { generateOptions } from '../../utils/Utils';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { Minus, Plus } from 'react-feather';
import { toast } from 'react-toastify';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import SelectedProductRow from '../SalesOrder/SelectedProductRow';
import ProductSelect from '../SalesOrder/ProductSelect';
import {
  fetchCreditbook,
  fetchCreditbooks,
  getCreditbook,
} from '../../app/reducers/Creditbook/creditbookSlice';

const TableHeader = ({ headers }) => {
  return (
    <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers?.map(header => (
          <th key={header.name} className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div style={header.style ?? {}} className="font-semibold text-left">
              {header.name}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
const Sale = () => {
  const { creditbook, loading: creditbookLoading } = useSelector(getCreditbook);
  console.log(creditbook?.docs?.[0].balance);
  const { location } = useSelector(getLocation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saleLocationInfoID = JSON.parse(localStorage.getItem('saleLocationInfoId'));

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: saleLocationInfoID ?? '',
      customerId: '',
      customerName: '',
      customerPhone: '',
      products: [],
      credit_balance: creditbook?.docs?.[0]?.balance ? creditbook?.docs[0].balance : 0,
    },
    validationSchema: Yup.object({
      customerId: Yup.string().required(),
      customerName: Yup.string().required(),
      customerPhone: Yup.string().required(),
      product: Yup.array().of(
        Yup.object({
          product: Yup.string(),
          productName: Yup.string(),
          sku: Yup.string(),
          qty: Yup.number(),
        })
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      let filterProduct = values?.products?.filter(ele => ele.product_sku);
      if (filterProduct.length > 0) {
        let payload = { ...values, products: filterProduct };
        const generatingNewSale = await authAxiosInstance.post('/sale', payload);
        if (generatingNewSale) {
          await toast.success('Sale Generated Successfully');
          resetForm();
        } else {
          toast.error('Error generating Sale');
        }
      } else {
        alert('Please Select Atleast one Barcode');
      }
    },
  });

  const onKeyPressBarcode = async (event, i, arrayHelpers) => {
    if (event.keyCode === 13 && event.target.value) {
      event.preventDefault();
      let checkExist = formik.values.products?.find(d => d.sku == event.target.value);

      const string = QueryString.stringify({
        masterSku: event.target.value,
      });

      const resp = await authAxiosInstance.get(`product?${string}`);
      const product = resp.data.data.docs[0];

      if (!product) {
        alert('Product Not found');
      }

      const stringInv = QueryString.stringify({
        product: product?._id,
        location: formik?.values?.location,
        populate: true,
      });

      const invRsp = await authAxiosInstance.get(`sale/current-inventory?${stringInv}`);

      const stock = invRsp?.data?.data;

      if (stock?.received <= 0) {
        return alert('Product out of stock');
      }

      if (!stock) {
        return alert('Stock not found ');
      }

      if (checkExist) {
        let incQty = checkExist.qty + 1;
        if (stock?.received < incQty) {
          return alert('Product out of stock');
        }
        let productIndex = formik.values.products?.findIndex(d => d.sku == event.target.value);
        formik.setFieldValue(`products.${productIndex}.qty`, incQty);
        formik.setFieldValue(`products.${i}.product`, '');
        formik.setFieldValue(`products.${i}.id`, '');
        return toast.success('Qty updated on product');
      }

      formik.setFieldValue(`products.${i}.sku`, product.masterSku);
      formik.setFieldValue(`products.${i}.productName`, product.name);

      formik.setFieldValue(`products.${i}.product`, product._id);
      formik.setFieldValue(`products.${i}.location`, formik?.values?.location);
      formik.setFieldValue(`products.${i}.qty`, 1);

      arrayHelpers.push('');
    }
  };

  const increment = async (e, i) => {
    e.stopPropagation();

    let data = formik?.values?.products[i];
    const stringInv = QueryString.stringify({
      product: data?.product,
      location: formik?.values?.location,
    });

    const invRsp = await authAxiosInstance.get(`sale/current-inventory?${stringInv}`);
    const stock = invRsp?.data?.data;

    if (stock?.received <= 0) {
      return alert('Product out of stock');
    }
    if (!stock) {
      return alert('Stock not found');
    }
    let incQty = data.qty + 1;
    if (stock?.received < incQty) {
      return alert('Product out of stock');
    }
    formik.setFieldValue(`products.${i}.qty`, incQty);
  };

  const decrement = async (e, i) => {
    e.stopPropagation();
    let data = formik.values.products[i];
    if (data.qty == 1) {
      window.alert('Not allowed');
    } else {
      let incQty = data.qty - 1;
      formik.setFieldValue(`products.${i}.qty`, incQty);
    }
  };

  return (
    <PageWithCard heading="Create Sale">
      <div className="mb-3 flex flex-row gap-3">
        <SecondaryButton
          onClick={e => {
            e.stopPropagation();
            navigate('/users/add-customer');
          }}
          type="button"
        >
          Add Customer
        </SecondaryButton>
        <SecondaryButton
          onClick={e => {
            e.stopPropagation();
            navigate('/location/assignLocation');
          }}
          type="button"
        >
          Assign Sale Location
        </SecondaryButton>
      </div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikAsyncSelect
          name="customerId"
          formik={formik}
          label="Search Customer"
          getOptions={async value => {
            const string = QueryString.stringify({ search: value });
            const customers = await authAxiosInstance.get(`users/customer?${string}`);
            const options = customers?.data?.data?.docs?.map(ele => ({
              label: `${ele?.firstName} ${ele?.lastName} / ${ele?.username}`,
              value: ele?._id,
            }));
            return options;
          }}
          onChange={async selectedOption => {
            const string = QueryString.stringify({
              _id: selectedOption?.value,
            });
            await dispatch(
              fetchCreditbooks({
                populate: true,
                'user._id': selectedOption?.value,
              })
            );
            const resp = await authAxiosInstance.get(`users/customer?${string}`);
            const user = resp?.data?.data?.docs[0];

            formik.setFieldValue('customerId', user._id);
            formik.setFieldValue('customerName', `${user.firstName} ${user.lastName}`);
            formik.setFieldValue('customerPhone', user.username);
          }}
        />

        {formik?.values?.customerId && (
          <div className="flex gap-4 w-full">
            <FormikInputGroup
              name="customerName"
              formik={formik}
              label="Customer Name"
              readOnly
              fullWidth
            />
            <FormikInputGroup
              name="customerPhone"
              formik={formik}
              label="Customer Phone"
              readOnly
              fullWidth
            />
            <FormikSelectGroup
              options={generateOptions({
                array: location?.docs ?? [],
                labelField: 'name',
                valueField: '_id',
              })}
              label="Location"
              name="location"
              formik={formik}
              isDisabled
            />
            <FormikInputGroup
              value={creditbook?.docs?.[0]?.balance ? creditbook?.docs[0].balance : 0}
              label="Credit balance"
              name="credit_balance"
              formik={formik}
              isDisabled
              readOnly
              fullWidth
            />
          </div>
        )}

        {formik?.values?.location && formik?.values?.customerId && (
          // <>
          //   <FormikProvider value={formik}>
          //     <FieldArray
          //       name="products"
          //       render={arrayHelpers => {
          //         return (
          //           <div className="flex flex-col gap-2">
          //             {/* <p>Address</p> */}
          //             <div>
          //               {formik.values.products.map((ele, index) => (
          //                 <div
          //                   className="relative p-4 mb-2"
          //                   style={{
          //                     border: '1px solid #d6c7c7',
          //                     borderRadius: '5px',
          //                   }}
          //                   key={index}
          //                 >
          //                   {!formik.values.products[index].sku && (
          //                     <FormikInputGroup
          //                       autoFocus={true}
          //                       formik={formik}
          //                       label={`Barcode${index + 1}`}
          //                       name={`products.${index}.id`}
          //                       onKeyDown={e => {
          //                         onKeyPressBarcode(e, index, arrayHelpers);
          //                       }}
          //                     />
          //                   )}

          //                   {formik.values.products[index].sku && (
          //                     <div className="flex gap-3">
          //                       <FormikInputGroup
          //                         formik={formik}
          //                         label={`sku`}
          //                         name={`products.${index}.sku`}
          //                         readOnly
          //                       />

          //                       <FormikInputGroup
          //                         formik={formik}
          //                         label={`Product Name`}
          //                         name={`products.${index}.productName`}
          //                         readOnly
          //                       />

          //                       <Minus
          //                         onClick={e => decrement(e, index)}
          //                         style={{ marginTop: '25px' }}
          //                       />
          //                       <FormikInputGroup
          //                         formik={formik}
          //                         label={`Qty`}
          //                         name={`products.${index}.qty`}
          //                         readOnly
          //                       />
          //                       <Plus
          //                         onClick={e => increment(e, index)}
          //                         style={{ marginTop: '25px' }}
          //                       />
          //                     </div>
          //                   )}

          //                   <div>
          //                     <DangerButton
          //                       className="mt-3"
          //                       onClick={() => {
          //                         arrayHelpers.remove(index);
          //                       }}
          //                       type="button"
          //                     >
          //                       Remove
          //                     </DangerButton>
          //                   </div>
          //                 </div>
          //               ))}
          //             </div>
          //             <div className="flex gap-3">
          //               <SecondaryButton
          //                 onClick={() => {
          //                   arrayHelpers.push('');
          //                 }}
          //                 type="button"
          //               >
          //                 Add More
          //               </SecondaryButton>

          //               {/* {formik.values.products &&
          //                 formik.values.products.length > 0 && (
          //                   <div className="mt-2">
          //                     <h1>
          //                       Total Quantity:-{" "}
          //                       <span className="font-bold">
          //                         {formik.values.products?.reduce(
          //                           (acc, ele) => acc + (ele.qty || 0),
          //                           0
          //                         )}
          //                       </span>
          //                     </h1>
          //                   </div>
          //                 )} */}
          //             </div>
          //           </div>
          //         );
          //       }}
          //     />
          //     {formik.errors['product'] &&
          //     !Array.isArray(formik.errors['product']) &&
          //     formik.errors['product'] ? (
          //       <p className="text-xs text-red-500">{formik.errors['product']}</p>
          //     ) : null}
          //   </FormikProvider>
          // </>
          <FormikProvider value={formik}>
            <FieldArray
              name="products"
              render={arrayHelpers => (
                <div className="flex flex-col gap-3">
                  <div>
                    <ProductSelect formik={formik} arrayHelpers={arrayHelpers} pageType="sale" />
                  </div>
                  <div className="w-full overflow-x-auto">
                    <table className=" ">
                      <TableHeader
                        headers={[
                          { name: '' },
                          { name: 'photo' },
                          { name: 'Product' },
                          { name: 'qty', style: { width: '40px' } },
                          { name: 'MRP', style: { width: '65px' } },
                          { name: 'Selling Price' },
                          { name: 'Discount' },
                          { name: 'Total Discount' },
                          { name: 'Tax type' },
                          { name: 'Tax Rate', style: { width: '40px' } },
                          { name: 'Tax AMT', style: { width: '40px' } },
                          { name: 'Total Tax' },
                          { name: 'Total Price' },
                        ]}
                      />
                      <tbody>
                        {formik.values.products.map((ele, index) => (
                          <SelectedProductRow
                            key={index}
                            formik={formik}
                            element={ele}
                            index={index}
                            arrayHelpers={arrayHelpers}
                            pageType="sale"
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            />
          </FormikProvider>
        )}
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default Sale;
