import React, { useEffect, useMemo, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useFormik } from "formik";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import {
  fetchProductPurchaseOrders,
  getProductPurchaseOrders,
  resetProductPurchaseOrderData,
} from "../../app/reducers/ProductPurchaseOrder/productPurchaseOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendors, getVendors } from "../../app/reducers/Users/VendorSlice";
import { generateOptions } from "../../utils/Utils";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import TableHeader from "../../components/Table/TableHeader";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import {
  createProductChallan,
  fetchProductChallans,
} from "../../app/reducers/ProductChallan/productChallanSlice";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import { array, object, string } from "yup";

const ReceivePurchaseOrder = () => {
  const storeLocation = JSON.parse(localStorage.getItem("saleLocationInfoId"));

  const [manufacturer, setManufacturer] = useState("");
  const [progressLoading, setProgressLoading] = useState(false);

  const [currentItem, setCurrentItem] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [totalItem, setTotalItem] = useState(0);

  const { vendor } = useSelector(getVendors);
  const { productPurchaseOrder, loading } = useSelector(
    getProductPurchaseOrders
  );
  const { location } = useSelector(getLocation);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchVendors());
    dispatch(fetchLocations());

    return () => {
      dispatch(resetProductPurchaseOrderData());
    };
  }, []);

  const productPurchaseOrderData = useMemo(
    () =>
      productPurchaseOrder?.docs
        ? productPurchaseOrder.docs
            .filter(
              (d) =>
                d.status != "completed" &&
                d.status != "settled" &&
                d.status != "direct"
            )
            .map((ele) => ({
              select: false,
              ...ele,
            }))
        : [],
    [productPurchaseOrder]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      manufacturer: manufacturer ?? "",
      poData: productPurchaseOrderData ?? [],
      challanNumber: "",
      challanDate: "",
      notes: "",
      location: storeLocation ?? "",
    },
    validationSchema: object({
      manufacturer: string().required(),
      poData: array(),
      challanNumber: string().required(),
      challanDate: string().required(),
      location: string().required(),
    }),
    onSubmit: async (values) => {
      try {
        const selectedPoData = values.poData.filter((item) => item.select);
        if (!selectedPoData.length) {
          return alert(
            "Please Select at least one purchase order to continue!"
          );
        }
        console.log(selectedPoData);
        setTotalItem(selectedPoData.length);
        const repeatChallan = await dispatch(
          fetchProductChallans({
            manufacturer: values?.manufacturer,
            challanNumber: values?.challanNumber,
          })
        );
        if (repeatChallan?.payload?.data?.totalDocs > 0) {
          alert("Challan Number already exist");
          return;
        }
        setProgressLoading(true);
        console.log("totalitem", selectedPoData.length);

        for (let i = 0; i < selectedPoData.length; i++) {
          const currentPo = selectedPoData[i];
          console.log(i);
          try {
            const payload = {
              product: currentPo?.product?._id,
              location: values?.location,
              manufacturer: values?.manufacturer,
              poRef: currentPo._id,
              challanNumber: values?.challanNumber,
              receivedQty: currentPo?.currentReceive ?? 0,
              damaged: currentPo?.currentDamage ?? 0,
              challanDate: values?.challanDate,
              notes: values?.notes,
            };
            // await dispatch(createProductChallan(payload));
            const productChallan = await authAxiosInstance.post(
              "/productChallan",
              payload
            );
            if (productChallan?.data) {
              setCurrentItem((prevState) => prevState + 1);
            }
          } catch (error) {
            console.log(error);
            setCurrentItem((prevState) => prevState + 1);
            toast.error("Error on Submit");
            setErrorData((prev) => [
              ...prev,
              {
                ...currentPo[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
        await dispatch(
          fetchProductPurchaseOrders({
            "manufacturer._id": values?.manufacturer,
            populate: true,
          })
        );
        console.log("after");
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <PageWithCard heading="Receive purchase order">
      {progressLoading ? (
        <ProgressBar
          totalItem={totalItem}
          currentItem={currentItem}
          errorData={errorData}
        />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <FormikSelectGroup
            formik={formik}
            label="Manufacturer"
            name="manufacturer"
            onChange={async (selectedValue) => {
              formik.setFieldValue("manufacturer", selectedValue?.value);

              await dispatch(
                fetchProductPurchaseOrders({
                  "manufacturer._id": selectedValue?.value,
                  populate: true,
                  status: { $ne: ["completed"] },
                })
              );
              setManufacturer(selectedValue?.value);
            }}
            required
            options={generateOptions({
              array: vendor ? vendor.docs : [],
              valueField: `_id`,
              labelField: "companyName",
            })}
          />
          <div>
            <FormikInputDateGroup
              formik={formik}
              required
              label="Challan Date"
              name="challanDate"
            />
            <FormikInputGroup
              required
              formik={formik}
              label="Challan Number"
              name="challanNumber"
            />
          </div>
          <FormikTextareaGroup formik={formik} label="Notes" name="notes" />
          <FormikSelectGroup
            name={"location"}
            label="Location"
            required
            formik={formik}
            // onChange={() => {}}
            options={generateOptions({
              array: location.docs ?? [],
              labelField: "name",
              valueField: "_id",
            })}
          />
          <table className="w-full">
            <TableHeader
              headers={[
                {
                  name: "select",
                },
                { name: "Product" },
                { name: "Po number" },
                { name: "Total Order" },
                { name: "Remaining" },
                { name: "Receive qty" },
                { name: "Damage qty" },
              ]}
            />
            <tbody>
              {formik.values.poData.map((po, index) => (
                <tr key={index} className="">
                  <td className="px-2 text-center">
                    {/* <FormikCheckbox
                  value={true}
                  formik={formik}
                  name={`products.${index}.select`}
                /> */}
                    <input
                      type="checkbox"
                      onChange={formik.handleChange}
                      name={`poData.${index}.select`}
                      checked={po.select}
                    />
                  </td>
                  <td className="px-2 text-center">{po.product.masterSku}</td>
                  <td className="px-2 text-center"> {po.poNumber}</td>
                  <td className="px-2 text-center">{po.quantity}</td>
                  <td className="px-2 text-center">{po.remaining}</td>
                  <td className="px-2  ">
                    <FormikInputGroup
                      readOnly={!po.select}
                      type="number"
                      name={`poData.${index}.currentReceive`}
                      formik={formik}
                      fullWidth
                    />
                  </td>
                  <td className="px-2  ">
                    <FormikInputGroup
                      fullWidth
                      readOnly={!po.select}
                      type="number"
                      name={`poData.${index}.currentDamage`}
                      formik={formik}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </form>
      )}
    </PageWithCard>
  );
};

export default ReceivePurchaseOrder;
