import React, { useState } from "react";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";

const DashboardLeadContent = ({
  todayCallScheduleData,
  weekCallScheduleData,
  previousCallScheduleData,
  todayMeetingData,
  weekMeetingData,
  previousWeekMeetingData,
  columns,
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const tabContent = [
    {
      data: todayCallScheduleData?.docs,
      heading: "Today's Call Schedule",
    },
    {
      data: weekCallScheduleData?.docs,
      heading: "Week's Call Schedule",
    },
    {
      data: previousCallScheduleData?.docs,
      heading: "Previous Week's Call Scheduled",
    },
    {
      data: todayMeetingData?.docs,
      heading: "Today's Meeting Schedule",
    },
    {
      data: weekMeetingData?.docs,
      heading: "Week's Meeting Schedule",
    },
    {
      data: previousWeekMeetingData?.docs,
      heading: "Previous Week's Meeting Scheduled",
    },
  ];

  return (
    <div>
      {/* Tabs */}
      <div className="flex flex-row items-center gap-4 mb-4 overflow-auto scrollable-content overscroll-x-auto p-4 border rounded-sm mx-8">
        {tabContent?.map((item, index) => (
          <div key={index}>
            <button
              className={
                activeIndex === index
                  ? "btn bg-indigo-500 hover:bg-indigo-600 text-white py-1 text-nowrap"
                  : "btn bg-white hover:bg-gray text-indigo-500 border border-gray-300 py-1 text-nowrap"
              }
              onClick={(e) => {
                e.stopPropagation();
                setActiveIndex(index);
              }}
            >
              {item.heading}
            </button>
          </div>
        ))}
      </div>

      {/* Tab Content */}
      {activeIndex !== -1 && tabContent[activeIndex]?.data?.length > 0 && (
        <div className="mx-8 mt-3">
          <TableWithHeadingAndSearch
            columns={columns}
            data={tabContent[activeIndex]?.data ?? []}
            heading={tabContent[activeIndex]?.heading}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardLeadContent;
