import React, { useEffect, useMemo, useState } from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const TotalCommittedStockDetailModal = ({
  productId,
  modalOpen,
  setModalOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [saleOrderData, setSaleOrderData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (productId) {
      const getCommittedStockDetail = async () => {
        try {
          setIsLoading(true);
          const string = QueryString.stringify({ productId });
          const resp = await authAxiosInstance.get(
            `productInventory/get-committed-stock-detail?${string}`
          );
          if (resp.data.data.docs.length) {
            setSaleOrderData(resp.data.data);
          } else {
            setSaleOrderData({});
          }
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      };
      getCommittedStockDetail();
    }
  }, [productId]);

  const columns = [
    {
      Header: "SrNo",
      Cell: ({ row }) => {
        return row.index + saleOrderData.pagingCounter;
      },
    },

    {
      Header: "Sale Order No",
      Cell: ({ row }) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/salesOrder/view/dispatch/${row.original._id}`);
            }}
            className="text-blue-500 underline cursor-pointer"
          >
            {row?.original?.saleOrderNumber}
          </div>
        );
      },
    },
    {
      Header: "Product",
      accessor: "products.name",
    },
    {
      Header: "Location",
      accessor: "location.name",
    },
    {
      Header: "Quantity",
      accessor: "products.quantity",
    },
    {
      Header: "Dispatched",
      Cell: ({ row }) => {
        const totalDispatched = row?.original?.dispatches?.reduce(
          (acc, crr) => {
            return acc + crr.products.quantity;
          },
          0
        );
        return totalDispatched ?? 0;
      },
    },
  ];

  const colsMemo = useMemo(() => columns ?? [], [columns]);

  const dataMemo = useMemo(
    () => (saleOrderData?.docs ? saleOrderData.docs : []),
    [saleOrderData]
  );

  return (
    <ModalBasic modalOpen={modalOpen} setModalOpen={setModalOpen}>
      {isLoading ? (
        <ClipLoader />
      ) : (
        <div className="p-4">
          <TableWithHeadingAndSearch data={dataMemo} columns={colsMemo} />
        </div>
      )}
    </ModalBasic>
  );
};

export default TotalCommittedStockDetailModal;
