import React, { useEffect } from 'react';
import { Folder } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  getMediaLibrary,
  fetchAssets,
  openFolderModal,
  deleteObject,
  openAssetsModal,
} from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import MediaLibraryItem from './MediaLibraryItem';
import AddFolderModal from './AddFolderModal';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import AddAssetModal from './AddAssetModal';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const MediaLibrary = () => {
  const location = useLocation();
  const { user } = useSelector(getAuth);

  const dispatch = useDispatch();
  const { assets, baseUrl, loading, folders, isFolderDeleted, hasOrganization } =
    useSelector(getMediaLibrary);
  const navigate = useNavigate();
  const folder = location.pathname.replace('/media-library', '')
    ? `${location.pathname.replace('/media-library/', '')}/`
    : '/';
  const decodedFolder = window.decodeURI(folder);
  useEffect(() => {
    dispatch(fetchAssets({ currentFolder: decodedFolder }));
  }, [location]);
  return (
    <>
      <AddFolderModal />
      <AddAssetModal />
      {loading ? (
        <ClipLoader />
      ) : (
        <main>
          {hasOrganization ? (
            <>
              {isFolderDeleted ? (
                <h1>Folder Deleted</h1>
              ) : (
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                  {/* Page header */}
                  <div className="sm:flex sm:justify-between sm:items-center mb-8">
                    {/* Left: Title */}
                    <div className="mb-4 sm:mb-0">
                      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        Media Library
                      </h1>
                    </div>

                    {/* Right: Actions */}
                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                      {/* Search form */}
                      <input type="text" />
                      {/* Add member button */}
                      <PrimaryButton
                        onClick={e => {
                          e.stopPropagation();
                          dispatch(openAssetsModal(true));
                        }}
                      >
                        Add Asset
                      </PrimaryButton>
                      <SecondaryButton
                        onClick={e => {
                          e.stopPropagation();
                          dispatch(openFolderModal(true));
                        }}
                      >
                        Add Folder
                      </SecondaryButton>
                      {folder !== '/' && (
                        <DangerButton
                          onClick={e => {
                            e.stopPropagation();
                            if (folders?.length || assets?.length) {
                              alert(' cannot delete non empty folder');
                            } else {
                              dispatch(
                                deleteObject({
                                  keys: decodedFolder,
                                  currentFolder: decodedFolder,
                                })
                              );
                            }
                          }}
                        >
                          Delete Folder
                        </DangerButton>
                      )}
                    </div>
                  </div>

                  {/* Cards */}
                  <div className="bg-white mb-8  p-4">
                    <h1 className="text-xl">Folders</h1>

                    <div className="grid grid-cols-4 mt-8">
                      {folders?.map((folderName, i) => (
                        <div
                          key={i}
                          className="flex flex-col items-center cursor-pointer"
                          onClick={() => {
                            navigate(`/media-library/${folderName}`);
                          }}
                        >
                          <Folder size={70} />
                          <p className="truncate">{folderName.replace(decodedFolder, '')}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-4">
                    <h1 className="text-xl">Files</h1>
                    <div className="grid grid-cols-3 mt-8 gap-6">
                      {assets?.map(asset => (
                        <MediaLibraryItem key={asset.Key} asset={asset} baseUrl={baseUrl} />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <h1>
              <a className="underline text-indigo-500" href={`/media-library/${user.organization}`}>
                Enter Base folder
              </a>
            </h1>
          )}
        </main>
      )}
    </>
  );
};

export default MediaLibrary;
