import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSales, getSales } from "../../app/reducers/Sale/saleSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import moment from "moment";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import {
  deleteSaleOrder,
  fetchSaleOrders,
  getSaleOrders,
} from "../../app/reducers/SaleOrder/saleOrderSlice";
import { Edit, Edit2, Trash } from "react-feather";
import { useFormik } from "formik";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { fetchCustomers } from "../../app/reducers/Users/CustomerSlice";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import QueryString from "qs";
import { authAxiosInstance, axiosInstance } from "../../utils/axiosConfig";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import ReceiveAmountModal from "../Books/Creditbook/creditBookModals/ReceiveAmountModal";

const ViewSaleOrder = () => {
  const [page, setPage] = useState(1);
  const { saleOrder, loading } = useSelector(getSaleOrders);
  const [receiveAmountModalOpen, setReceiveAmountModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedSaleOrder, setSelectedSaleOrder] = useState(null);
  // const { customer, loading:customerLoading } = useSelector(getSaleOrders);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      fetchSaleOrders({
        populate: true,
      })
    );
    // dispatch(fetchCustomers())
  }, [page]);

  const columns = [
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format("DD/MM/YYYY")
          : "No Date Found";
      },
    },
    { Header: "Customer Name", accessor: "customer.companyName" },
    {
      Header: "Location",
      accessor: "location.name",
    },
    {
      Header: "Sale Order Number",
      Cell: ({ row }) => {
        return <div>{row?.original?.saleOrderNumber}</div>;
      },
    },
    {
      Header: "Qty",
      Cell: ({ row }) => {
        const totalSaleOrder = row?.original?.products?.reduce(
          (acc, { quantity }) => {
            return quantity ? acc + quantity : 0;
          },
          0
        );
        return totalSaleOrder;
      },
    },
    {
      Header: "total amount",
      Cell: ({ row }) => {
        const totalAmount = row?.original?.products?.reduce(
          (acc, { total_price }) => {
            return total_price ? acc + total_price : 0;
          },
          0
        );
        return ` ₹${totalAmount}`;
      },
    },
    {
      Header: "pending amount",
      Cell: ({ row }) => {
        const totalAmount = row?.original?.products?.reduce(
          (acc, { total_price }) => {
            return total_price ? acc + total_price : 0;
          },
          0
        );
        const totalReceivedAmount = row?.original?.received_amount?.reduce(
          (acc, { amount }) => {
            return amount ? acc + amount : 0;
          },
          0
        );
        return ` ₹${totalAmount - totalReceivedAmount}`;
      },
    },
    {
      Header: "Received amount",
      Cell: ({ row }) => {
        const totalReceivedAmount = row?.original?.received_amount?.reduce(
          (acc, { amount }) => {
            return amount ? acc + amount : 0;
          },
          0
        );
        return ` ₹${totalReceivedAmount}`;
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const totalAmount = row?.original?.products?.reduce(
          (acc, { total_price }) => {
            return total_price ? acc + total_price : 0;
          },
          0
        );
        const totalReceivedAmount = row?.original?.received_amount?.reduce(
          (acc, { amount }) => {
            return amount ? acc + amount : 0;
          },
          0
        );
        return (
          <div className="flex gap-3">
            {totalAmount - totalReceivedAmount >= 0 && (
              <PrimaryButton
                onClick={(e) => {
                  e.stopPropagation();
                  setReceiveAmountModalOpen(true);
                  setSelectedCustomer(row.original.customer);
                  setSelectedSaleOrder(row.original);
                }}
              >
                Receive Amount
              </PrimaryButton>
            )}
            <PrimaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate(`dispatch/${row?.original?._id}`);
              }}
            >
              View dispatched
            </PrimaryButton>
            <button
              onClick={() => navigate(`/salesOrder/edit/${row.original._id}`)}
            >
              <Edit />{" "}
            </button>
            <button
              onClick={() => {
                const confirmAction = confirm(
                  "Are you sure you want to delete this order?"
                );
                if (confirmAction) {
                  dispatch(deleteSaleOrder({ id: row.original._id }));
                }
              }}
            >
              <Trash color="red" />
            </button>
          </div>
        );
      },
    },
  ];

  const dataMemo = useMemo(() => {
    return saleOrder?.docs ?? [];
  }, [saleOrder?.docs]);

  const columnMemo = useMemo(() => columns ?? [], [columns]);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      let filter = {
        createdAt: {
          $gt: formik?.values?.from,
          $lt: formik?.values?.to,
        },
        "products.product._id": { $in: formik.values.product },
        "location._id": formik?.values?.location,
        customerId: formik.values.customerId,
        populate: true,
        search: search,
        page,
      };
      dispatch(fetchSales(filter));
    }, 300),
    []
  );

  const formik = useFormik({
    initialValues: {
      customer: "",
    },
    onSubmit: async (values) => {
      try {
        await dispatch(
          fetchSaleOrders({
            "customer._id": values.customer,
            populate: true,
          })
        );
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <PageWithCard heading="View Sales Order">
      <ReceiveAmountModal
        isOpen={receiveAmountModalOpen}
        setIsOpen={setReceiveAmountModalOpen}
        data={selectedCustomer}
        pageType="saleOrder"
        saleOrder={selectedSaleOrder}
      />
      <form onSubmit={formik.handleSubmit} className="flex gap-3 ">
        <div className="w-full">
          <FormikAsyncSelect
            formik={formik}
            name={"customer"}
            label="Customer"
            getOptions={async (inputValue) => {
              const string = QueryString.stringify({ search: inputValue });
              const resp = await authAxiosInstance.get(
                `/users/customer?${string}`
              );
              console.log(resp);
              const options = resp.data.data.docs.map((customer) => ({
                label: `${customer.firstName} ${customer.lastName} - ${customer.companyName}`,
                value: customer._id,
              }));
              return options;
            }}
          />
        </div>
        <div className="mt-auto">
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>

      <div className="mt-2">
        <TableWithHeadingAndGlobalSearch
          heading="Sales Order"
          data={dataMemo}
          loading={loading}
          columns={columnMemo}
          searchFunction={(search) => {
            debouncedSearch(search);
          }}
        />
        <PaginationClassic paginationDetails={saleOrder} setPage={setPage} />
      </div>
    </PageWithCard>
  );
};

export default ViewSaleOrder;
