import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProductChallan,
  fetchProductChallanByNo,
  fetchProductChallans,
  getProductChallans,
} from '../../app/reducers/ProductChallan/productChallanSlice';
import { useFormik } from 'formik';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { ClipLoader } from 'react-spinners';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { Edit2, Trash } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { generateBarcodeDataByQty } from '../../utils/generateBarcodeData';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import PaymentModel from './PaymentModel';

const ViewDirectProductPurchaseOrder = () => {
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [paymentModel, setPaymentModel] = useState(false);
  const [editData, setData] = useState(null);

  const [pagination, setPagination] = useState(null);
  const { location } = useSelector(getLocation);
  const { vendor } = useSelector(getVendors);
  const { baseUrl } = useSelector(getMediaLibrary);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      location: '',
      manufacturer: '',
      from: moment().startOf('day').valueOf(),
      to: moment().endOf('day').valueOf(),
    },
    onSubmit: async values => {
      let filterData = {
        status: 'directReceiveOrder',
        createdAt: {
          $gt: values?.from,
          $lt: values?.to,
        },
        populate: true,
        page,
      };
      if (values?.location) {
        filterData['location._id'] = values?.location;
      }
      if (values?.manufacturer) {
        filterData['manufacturer._id'] = values?.manufacturer;
      }
      fetchDirectProductPurchaseChallanOrder(filterData);
    },
  });

  const fetchDirectProductPurchaseChallanOrder = async data => {
    setLoading(true);
    let string = QueryString.stringify(data);
    let resp = await authAxiosInstance.get(`/productChallan/challanNo?${string}`);
    console.log(resp);
    if (resp?.data?.data) {
      setProductData(resp?.data?.data);
    }
    setLoading(false);
  };

  useEffect(async () => {
    let data = {
      status: 'directReceiveOrder',
      createdAt: {
        $gt: formik?.values?.from,
        $lt: formik?.values?.to,
      },
      populate: true,
      page,
    };
    await fetchDirectProductPurchaseChallanOrder(data);
    dispatch(fetchLocations());
    dispatch(fetchVendors());
  }, [page]);

  const columns = [
    {
      Header: 'Select',
      Cell: ({ row, data }) => (
        <input
          type="checkbox"
          checked={selectedRows.map(ele => ele.id).includes(row.original._id)}
          onChange={e => {
            e.preventDefault();

            let obj = {
              productDetail: {
                productName: row?.original?.product?.name,
                masterSku: row?.original?.product?.masterSku,
              },
              quantity: row?.original?.receivedQty,
              id: row?.original?._id,
            };
            if (e.target.checked) {
              setSelectedRows([...selectedRows, obj]);
            } else {
              const data = selectedRows.filter(item => item.id !== row.original._id);
              setSelectedRows(data);
            }
          }}
        />
      ),
    },
    {
      Header: 'SR.No',
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return row?.original?.challanDate
          ? moment(row?.original?.challanDate).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    {
      Header: 'Challan No',
      Cell: ({ row }) => {
        return (
          <Link to={`${row?.original._id}`}>
            <div className="text-blue-600 underline cursor-pointer">{row?.original?._id}</div>
          </Link>
        );
      },
    },
    {
      Header: 'manufacturer',
      Cell: ({ row }) => {
        return (
          <Link to={`/users/view-vendor/credit/${row.original.manufacturer?._id}`}>
            <div className="text-blue-600 underline cursor-pointer">
              {row?.original?.manufacturer.companyName}
            </div>
          </Link>
        );
      },
    },
    {
      Header: 'totalAmount',
      Cell: ({ row }) => {
        return row?.original?.totalAmount && Math.ceil(row?.original?.totalAmount);
      },
    },
    {
      Header: 'Remaining Amount',
      Cell: ({ row }) => {
        return (
          <div className="text-blue-600">
            {Math.ceil(row?.original?.totalAmount) -
              row?.original?.payments.reduce((acc, item) => acc + item.amount, 0)}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div
            className="flex flex-row gap-3"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {row?.original?.payments.reduce((acc, item) => acc + item.amount, 0) >=
            Math.ceil(row?.original?.totalAmount) ? (
              '---'
            ) : (
              <PrimaryButton
                onClick={e => {
                  e.stopPropagation();
                  setData(row.original);
                  setPaymentModel(true);
                }}
              >
                Pay Amount
              </PrimaryButton>
            )}
            {/* <Edit2
              color="blue"
              onClick={() =>
                navigate(`/product/editDirectProductPurchaseOrder/${row?.original?._id}`)
              }
            />
            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteProductChallan({ id: row?.original?._id }));
                await dispatch(
                  fetchProductChallans({
                    status: 'directReceiveOrder',
                    populate: true,
                  })
                );
              }}
            /> */}
          </div>
        );
      },
    },
  ];

  const dataMemo = useMemo(() => {
    return productData?.docs ?? [];
  }, [productData]);

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <PageWithCard>
      <PaymentModel
        isOpen={paymentModel}
        setIsOpen={setPaymentModel}
        data={editData}
        setProductData={setProductData}
        fetchOrders={fetchDirectProductPurchaseChallanOrder}
        formikData={formik}
      />
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikSelectGroup
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: 'name',
            valueField: '_id',
          })}
          name="location"
          formik={formik}
          label="Location"
        />
        <FormikSelectGroup
          options={generateOptions({
            array: vendor?.docs ?? [],
            labelField: 'companyName',
            valueField: '_id',
          })}
          name="manufacturer"
          formik={formik}
          label="Manufacturer"
        />
        <FormikInputDateGroup name="from" label="From" formik={formik} />
        <FormikInputDateGroup name="to" label="To" formik={formik} />
        <div>
          {loading || formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      {selectedRows?.length > 0 && (
        <div className="mb-3 mt-3">
          <PrimaryButton onClick={() => generateBarcodeDataByQty(selectedRows)} type="button">
            Print
          </PrimaryButton>
        </div>
      )}
      <div className="mt-3">
        <TableWithHeadingAndSearch
          heading="Direct Purchase Order Data"
          columns={columnsMemo}
          data={dataMemo}
        />
        <PaginationClassic setPage={setPage} paginationDetails={productData} />
      </div>
    </PageWithCard>
  );
};

export default ViewDirectProductPurchaseOrder;
